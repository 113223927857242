export const ID_CONVENIO_CREDCESTA = 76
export const LEMIT_COLUMN_NAME_WHATSAPP = 'whatsapp'
export const LEMIT_COLUMN_NAME_NUMBER = 'numero'
export const LEMIT_COLUMN_NAME_DDD = 'ddd'
export const LEMIT_FIELD_PHONE = 'celulares'
export const HEADERS_PROSPECT_FILE = [
  'CPF',
  'NOME',
  'DATA NASCIMENTO',
  'DATA ADMISSÃO',
  'AGENCIA',
  'CONTA'
]
export const HEADERS_QUERY_BATCH_FILE = [
  'NOME',
  'CPF',
  'D. NASC.',
  'CONVEN.',
  'AGENCIA',
  'CONTA',
  'TEL 1',
  'TEL 2',
  'TEL 3',
  'DATA.AD',
  'EMAIL',
  'CEP',
  'UF',
  'MATRIC.',
  'MARGEM CONSIGNADO',
  'MARGEM CARTÃO DE CREDITO',
  'MARGEM CARTÃO BENEFICIO',
  'PRODUTO',
  'COMISSIONADO',
  'POSSUI CONTRATO REF. MASTER',
  'POSSUI CONTRATO REF. SANTANDER',
  'VLR DISP. MASTER',
  'IDADE',
  'TIPO DE SERVIDOR'
]

export enum STEP_SIM {
  SIMULATION = 'Simulação',
  PROPOSAL = 'Proposta'
}

export enum SIM_TYPES_FORM {
  BANK_DATA = 'dados bancários',
  TOKEN = 'token',
  SELFIE = 'selfie',
  RG = 'identidade'
}

export enum SIM_STATUS {
  PENDING = 'Pendente',
  FINISHED = 'Concluído',
  WITH_PENDING = 'COM PENDENCIA',
  IN_PROGRESS = 'EM ANDAMENTO'
}
export enum STEP_CREDCESTA {
  SIMULATION = 'Simulação',
  PROPOSAL = 'Proposta'
}

export enum CREDCESTA_STATUS {
  PENDING = 'Pendente',
  FINISHED = 'Concluído',
  IN_REVIEW = 'Em Análise'
}
