import React from 'react'
import PrivateRoutes from '../Route'
import Create from '../../pages/Commercial/DistributionCampaign/Create'
import List from '../../pages/Commercial/DistributionCampaign/List'
import { CustomSwitch } from '../../components/CustomSwitch'

export const DistributionCampaignsRouter = (): JSX.Element => (
  <CustomSwitch>
    <PrivateRoutes
      path="/commercial/distributionCampaign"
      exact
      component={List}
    />
    <PrivateRoutes
      path="/commercial/distributionCampaign/create"
      exact
      component={Create}
    />
  </CustomSwitch>
)
