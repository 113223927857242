export const headers = [
  { name: 'Nome', field: 'name', sortable: false },
  { name: 'Convênio', field: 'agreement_group_name', sortable: false },
  { name: 'Parceiro', field: 'partner_store_name', sortable: false },
  {
    name: 'Quantidade Solicitada',
    field: 'quantity_requested',
    sortable: false
  },
  {
    name: 'Quantidade Distribuída',
    field: 'quantity_prospect_distributed',
    sortable: false
  },
  {
    name: 'Quantidade Trabalhada',
    field: 'worked_prospect',
    sortable: false
  },
  {
    name: 'Criado em',
    field: 'created_at',
    sortable: false,
    custom: true
  }
]
