import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Container from '../../../../components/Container'
import DataTable from '../../../../components/DataTable'
import { nameEntity, nameSource } from '../domain/info'
import { toolsList } from '../domain/tools'
import { breadcrumbList } from '../domain/breadcrumb'
import { headers, headersDashboardProspect } from '../domain/headers'
import moment from 'moment'
import { ContainerDashboard, FilterContainer } from './style'
import { Input, Select } from 'components/Form'
import {
  dateInputFormat,
  LOCAL_STORAGE_KEYS,
  MANAGER_ROLES,
  PROSPECT_STATUS
} from 'common/constants'
import { useLoading } from 'hooks/loading'
import api from 'services/api'
import Modal from 'components/Modal'
import { FormExport } from '../components/FormExport'
import { toolsExport } from '../domain/tools/list'
import { Datalist } from 'components/Datalist'
import { useLocation } from 'react-router-dom'
import { Loading } from 'components/Loading'
import { useAuth } from 'hooks/auth'
import CustomDataTable from 'components/CustomDataTable'
import { convertStringToBase64 } from 'utlis/base64'
import {
  BRL,
  genericMaskWithTwoZero,
  genericMaskWithTwoZeroWithPoint,
  phoneMask
} from 'utlis/mask'
import { Date as DatePicker } from 'components/Form/date'
import { IMaskInput } from 'react-imask'

type Filters = {
  id?: string
  status?: string
  name?: string
  agreement_group_id?: string
  cpf?: string
}
export interface CallbackDataTableProps {
  items: any[]
  params: any
  totalItems: number
}
export interface CallbackDataTableResponse {
  items: any[]
  params: any
  maxPage: number
  totalItems: number
}
export function callbackLoadDataProspect({
  items,
  totalItems,
  params
}: CallbackDataTableProps) {
  const getIds = items.map(item => item.id)
  sessionStorage.setItem(
    LOCAL_STORAGE_KEYS.PROSPECT_LIST,
    JSON.stringify({
      params,
      totalItems,
      maxPage: Math.ceil(totalItems / 50),
      items: getIds
    })
  )
}

function convertToDecimal(money: string) {
  if (money?.toString().includes(',')) {
    return BRL(money).value
  }

  const parsedValue = Number(money)

  return BRL(parsedValue).value
}

type GetResultApiNames =
  | 'credcesta'
  | 'santander_margem'
  | 'credcesta_name'
  | 'lemit'

const RESULT_PER_API_NAME: Record<
  GetResultApiNames,
  ({ queryApi }: { queryApi: any }) => any
> = {
  santander_margem: ({ queryApi }: { queryApi: any }) => {
    const result = { ...queryApi?.result }

    if (!result.margem) {
      return undefined
    }

    if (!result.margem?.length) {
      return undefined
    }
    return {
      margin: convertToDecimal(result.margem)
    }
  },
  credcesta: ({ queryApi }: { queryApi: any }) => {
    const result = { ...queryApi?.result }

    if (!result?.[0]) {
      return undefined
    }
    return {
      limit_available: convertToDecimal(result?.[0]?.limiteDisponivel),
      query_date: queryApi?.query_date
    }
  },
  credcesta_name: ({ queryApi }: { queryApi: any }) => {
    const result = { ...queryApi?.result }

    if (!result?.[0]) {
      return undefined
    }
    return {
      name: result?.[0]?.nome
    }
  },
  lemit: ({ queryApi }: { queryApi: any }) => {
    const result = { ...queryApi?.result }

    if (!result?.[0]) {
      return undefined
    }
    return {
      name: result?.[0]?.nome
    }
  }
}

const API_NAME_MAP = {
  santander_margem: 'santander_margem',
  credcesta: 'credcesta',
  credcesta_name: 'credcesta',
  lemit: 'lemit'
}

function getResultApi({
  data,
  api_name
}: {
  data: any[]
  api_name: GetResultApiNames
}) {
  const findResultPerApi = data.find(
    item => item.api_name === API_NAME_MAP[api_name]
  )

  if (!findResultPerApi) {
    return null
  }

  return RESULT_PER_API_NAME[api_name]({ queryApi: findResultPerApi })
}

export const CLIENTS_RISK_CREDIT = {
  VERY_LOW: 'very_low',
  LOW: 'low',
  MODERATE: 'moderate',
  HIGH: 'high',
  VERY_HIGH: 'very_high'
}

export const CLIENTS_RISK_CREDIT_MAP = {
  BAIXISSIMO: CLIENTS_RISK_CREDIT.VERY_LOW,
  BAIXO: CLIENTS_RISK_CREDIT.LOW,
  MEDIO: CLIENTS_RISK_CREDIT.MODERATE,
  ALTO: CLIENTS_RISK_CREDIT.HIGH,
  ALTISSIMO: CLIENTS_RISK_CREDIT.VERY_HIGH
}

export const CLIENTS_RISK_CREDIT_OPTIONS = Object.entries(
  CLIENTS_RISK_CREDIT_MAP
).map(([key, value]) => ({
  name: key,
  value
}))

const List: React.FC = () => {
  const { user } = useAuth()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const { activeLoading, disableLoading } = useLoading()
  const [statusValue, setStatusValue] = useState<string>('')
  const [cpfValue, setCpfValue] = useState<string>('')
  const [nameValue, setNameValue] = useState<string>('')
  const [agencyName, setAgencyName] = useState<string>('')
  const [startMarginValueValue, setStartMarginValueValue] = useState<string>('')
  const [startMarginPositiveValue, setStartMarginPositiveValue] =
    useState<string>('1')
  const [riskCreditValue, setRiskCreditValue] = useState<string>('')
  const [endMarginValueValue, setEndMarginValueValue] = useState<string>('')
  const [startBirthDateValue, setStartBirthDateValue] = useState<Date>()
  const [endBirthDateValue, setEndBirthDateValue] = useState<Date>()
  const [prospectIdValue, setProspectIdValue] = useState<string>('')
  const [agreementGroupValue, setAgreementGroupValue] = useState({
    name: '',
    value: 0,
    id: 0
  })
  const [modalExportProspect, setModalExportProspect] = useState(false)
  const [openDataTable, setOpenDataTable] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [parameters, setParameters] = useState<any[] | undefined>([])
  const [partnerDashboard, setPartnerDashboard] = useState([])
  const [agreementGroups, setAgreementGroups] = useState<
    { id: number; value: number; name: string }[]
  >([{ id: 0, value: 0, name: '' }])
  const formatDateOut = 'DD/MM/YYYY'

  const getAgreementGroups = useCallback(async () => {
    activeLoading()
    const response = await api.get('/operational/agreementGroups')
    const agreementGroupsSelect = response.data?.map(
      (agreementGroup: { id: number; name: string }) => ({
        value: agreementGroup.id,
        name: agreementGroup.name,
        id: agreementGroup.id
      })
    )
    setAgreementGroups(agreementGroupsSelect)
    disableLoading()
  }, [activeLoading, disableLoading])

  const handleSetFilter = useCallback(
    (filter?: Filters) => {
      const filters = filter || {
        id: prospectIdValue || undefined,
        status: statusValue || undefined,
        name: nameValue || undefined,
        agency: agencyName || undefined,
        risk_credit: riskCreditValue || undefined,
        agreement_group_id: agreementGroupValue?.id || undefined,
        cpf: cpfValue || undefined,
        start_margin_value:
          BRL(
            `${
              startMarginPositiveValue === '1' ? '' : '-'
            }${startMarginValueValue}`
          ).value || undefined,
        end_margin_value:
          BRL(
            `${
              startMarginPositiveValue === '1' ? '' : '-'
            }${endMarginValueValue}`
          ).value || undefined,
        start_birth_date: startBirthDateValue || undefined,
        end_birth_date: endBirthDateValue || undefined
      }
      sessionStorage.setItem(
        LOCAL_STORAGE_KEYS.LIST_PROSPECTS_FILTERS,
        JSON.stringify(filters)
      )
      setParameters([
        {
          ...filters
        }
      ])
    },
    [
      agencyName,
      agreementGroupValue?.id,
      cpfValue,
      endBirthDateValue,
      endMarginValueValue,
      nameValue,
      prospectIdValue,
      riskCreditValue,
      startBirthDateValue,
      startMarginPositiveValue,
      startMarginValueValue,
      statusValue
    ]
  )

  useEffect(() => {
    const storageFilters = sessionStorage.getItem(
      LOCAL_STORAGE_KEYS.LIST_PROSPECTS_FILTERS
    )
    const startDate = queryParams.get('start_date')
    const endDate = queryParams.get('end_date')
    let copyParams = {} as {
      id?: string
      status?: string
      name?: string
      start_date?: string
      end_date?: string
      agreement_group_id?: number
      cpf?: string
    }
    let open = false
    if (storageFilters) {
      copyParams = { ...JSON.parse(storageFilters) }

      setProspectIdValue(copyParams.id)
      setStatusValue(copyParams.status)
      setNameValue(copyParams.name)
      setAgreementGroupValue({
        id: copyParams.agreement_group_id,
        name: '',
        value: copyParams.agreement_group_id
      })
      setCpfValue(copyParams.cpf)
      open = true
    }
    if (startDate) {
      copyParams = {
        start_date: startDate
      }
      open = true
    }
    if (endDate) {
      copyParams = {
        ...copyParams,
        end_date: endDate
      }
      open = true
    }
    setParameters([copyParams])
    setOpenDataTable(open)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getAgreementGroups()
  }, [getAgreementGroups])

  const handleClickModalQueryOnClose = () => {
    setModalExportProspect(false)
  }

  const loadPartnerDashboard = useCallback(async () => {
    setIsLoading(true)
    try {
      const responsePartnerDashboard = await api.post(
        '/commercial/prospects/report/partnerDashboard'
      )
      setPartnerDashboard(responsePartnerDashboard.data)
    } catch (error) {}
    setIsLoading(false)
  }, [])

  useEffect(() => {
    if (MANAGER_ROLES.includes(user?.role_id)) return
    loadPartnerDashboard()
  }, [loadPartnerDashboard, user?.role_id])

  const toolsMount = useMemo(() => {
    let defaultTools = [
      ...toolsList,
      toolsExport(() => setModalExportProspect(true))
    ]
    if (user?.role_id !== 1) {
      defaultTools = []
    }
    return defaultTools
  }, [user?.role_id])
  if (!parameters || isLoading) {
    return <Loading isActive />
  }
  return (
    <Container
      pageTitle="Listagem"
      breadcrumb={breadcrumbList}
      tools={toolsMount}
    >
      {!MANAGER_ROLES.includes(user?.role_id) && (
        <ContainerDashboard>
          <h3>Meus resultados</h3>
          <CustomDataTable
            customItems={partnerDashboard}
            headers={headersDashboardProspect}
            actions={[
              {
                name: 'Filtrar',
                title: 'Filtrar',
                spanIcon: 'fa fa-filter',
                onClick: item => {
                  handleSetFilter({
                    agreement_group_id: item.agreement_group_id
                  })
                  setAgreementGroupValue({
                    id: item.agreement_group_id,
                    name: item.agreement_group_name,
                    value: item.agreement_group_id
                  })
                  setOpenDataTable(true)
                }
              }
            ]}
          />
        </ContainerDashboard>
      )}

      <FilterContainer>
        <div className="row">
          <Datalist
            data={agreementGroups}
            label="Grupo de Convênio"
            setValue={setAgreementGroupValue}
            value={agreementGroupValue}
          />
          <div className="col-md-3">
            <Select
              name="status"
              label="Status de atendimento"
              id="status"
              value={statusValue}
              onChange={({ target }) => setStatusValue(target.value)}
              options={PROSPECT_STATUS}
              blank
            />
          </div>
          <div className="col-md-3">
            <Input
              name="cpf"
              label="CPF"
              id="cpf"
              onChange={({ target }) => setCpfValue(target.value)}
              value={cpfValue}
            />
          </div>
          {/* <div className="col-md-3">
            <Input
              name="id"
              label="Cod"
              id="id"
              onChange={({ target }) => setProspectIdValue(target.value)}
              value={prospectIdValue}
            />
          </div> */}
          {/* <div className="col-md-3">
            <Input
              name="name"
              label="Nome"
              id="name"
              onChange={({ target }) => setNameValue(target.value)}
              value={nameValue}
            />
          </div> */}
          <div className="col-md-3">
            <Input
              name="agency"
              label="Agencia"
              id="agency"
              onChange={({ target }) => setAgencyName(target.value)}
              value={agencyName}
            />
          </div>
          <div className="col-md-3">
            <Select
              name="start_margin_positive"
              label="Tipo de Margem"
              id="start_margin_positive"
              value={startMarginPositiveValue}
              onChange={({ target }) =>
                setStartMarginPositiveValue(target.value)
              }
              options={[
                {
                  name: 'Positiva',
                  value: '1'
                },
                {
                  name: 'Negativa',
                  value: '0'
                }
              ]}
              blank
            />
          </div>
          <div className="col-md-3">
            <Input
              name="start_margin_value"
              label="Margem inicial"
              id="start_margin_value"
              onChange={({ target }) =>
                setStartMarginValueValue(
                  genericMaskWithTwoZeroWithPoint(target.value)
                )
              }
              value={startMarginValueValue}
            />
          </div>
          <div className="col-md-3">
            <Input
              name="end_margin_value"
              label="Margem final"
              id="end_margin_value"
              onChange={({ target }) =>
                setEndMarginValueValue(genericMaskWithTwoZero(target.value))
              }
              value={endMarginValueValue}
            />
          </div>
          <DatePicker
            name="start_birth_date"
            className="col-md-3"
            label="Data de nascimento inicial"
            placeholderText="DD/MM/AAAA"
            dateFormat="dd/MM/yyyy"
            selected={startBirthDateValue}
            customInput={
              <IMaskInput
                mask={Date}
                pattern={'d/m/Y'}
                format={date => {
                  return moment(date).format(dateInputFormat)
                }}
                parse={value => {
                  return moment(value, dateInputFormat).toDate()
                }}
              />
            }
            onChange={date => {
              setStartBirthDateValue(date)
            }}
            controlled
          />
          <DatePicker
            name="end_birth_date"
            className="col-md-3"
            label="Data de nascimento final"
            placeholderText="DD/MM/AAAA"
            dateFormat="dd/MM/yyyy"
            selected={endBirthDateValue}
            customInput={
              <IMaskInput
                mask={Date}
                pattern={'d/m/Y'}
                format={date => {
                  return moment(date).format(dateInputFormat)
                }}
                parse={value => {
                  return moment(value, dateInputFormat).toDate()
                }}
              />
            }
            onChange={date => {
              setEndBirthDateValue(date)
            }}
            controlled
          />
          <div className="col-md-3">
            <Select
              name="risk_credit"
              label="Risco de crédito"
              id="risk_credit"
              value={riskCreditValue}
              onChange={({ target }) => {
                setRiskCreditValue(target.value)
              }}
              options={CLIENTS_RISK_CREDIT_OPTIONS}
              blank
            />
          </div>
        </div>
        <footer>
          <button
            className="btn btn-light-primary"
            onClick={() => {
              setStatusValue('')
              setStartMarginValueValue('')
              setEndMarginValueValue('')
              setRiskCreditValue('')
              setAgencyName('')
              setStartBirthDateValue(null)
              setEndBirthDateValue(null)
              setAgreementGroupValue({
                name: '',
                value: 0,
                id: 0
              })
              setParameters([])
              setOpenDataTable(false)
            }}
          >
            LIMPAR
          </button>
          <button
            className="btn btn-primary"
            onClick={() => {
              handleSetFilter()
              setOpenDataTable(true)
            }}
          >
            BUSCAR
          </button>
        </footer>
        <div className="separator" />
      </FilterContainer>

      {openDataTable && (
        <DataTable
          entity={nameEntity}
          source={nameSource}
          format={{ orderBy: '' }}
          callbackLoadData={callbackLoadDataProspect}
          notHasChildren
          onlyView
          headers={headers}
          searchParameters={parameters}
          customHeaders={[
            {
              name: 'Status',
              field: 'status',
              sortable: false,
              element: item => {
                const prospectHistories = item?.prospectHistories
                let findLastHistoryStatus = 'Distribuído'
                if (prospectHistories.length) {
                  const find = (prospectHistories as ProspectQueryApiResponse[])
                    .filter(item => {
                      if (user?.oldUser) {
                        return item.partner_id === user.oldUser?.uid
                      }
                      return item.created_by === Number(user.id)
                    })
                    .sort(
                      (currentItem, nextItem) => nextItem.id - currentItem.id
                    )
                    .find(item => {
                      if (user?.oldUser) {
                        return item.partner_id === user.oldUser?.uid
                      }
                      return item.created_by === Number(user.id)
                    })
                  if (find) {
                    findLastHistoryStatus = find.status
                  }
                }
                return <p>{findLastHistoryStatus || ''}</p>
              }
            },
            {
              name: 'Nome',
              field: 'name',
              sortable: true,
              element: item => {
                if (item.name) {
                  return <p>{item.name}</p>
                }
                const result = getResultApi({
                  api_name: 'credcesta_name',
                  data: item.prospectQueryApis
                })

                if (!result) {
                  return <p></p>
                }

                return <p>{result.name}</p>
              }
            },
            {
              name: 'Data de Nascimento',
              field: 'birth_date',
              sortable: true,
              element: item => (
                <p>
                  {item.birth_date
                    ? moment(item.birth_date, formatDateOut).format(
                        formatDateOut
                      )
                    : ''}
                </p>
              )
            },
            {
              name: 'Data de Admissão',
              field: 'admission_date',
              sortable: true,
              element: item => (
                <p>
                  {item.admission_date
                    ? moment(item.admission_date, formatDateOut).format(
                        formatDateOut
                      )
                    : ''}
                </p>
              )
            },
            {
              name: 'Limite disponível Banco Master',
              field: 'limit_credcesta',
              sortable: false,
              element: item => {
                const result = getResultApi({
                  api_name: 'credcesta',
                  data: item.prospectQueryApis
                })

                if (!result) {
                  return <p></p>
                }

                if (!result.limit_available) {
                  return <p></p>
                }

                return <p>{BRL(result.limit_available).format()}</p>
              }
            },
            {
              name: 'Santander Margem para Consignado',
              field: 'limit_santander_margem',
              sortable: false,
              element: item => {
                const result = getResultApi({
                  api_name: 'santander_margem',
                  data: item.prospectQueryApis
                })

                if (!result) {
                  return <p></p>
                }

                if (!result.margin) {
                  return <p></p>
                }

                return <p>{BRL(result.margin).format()}</p>
              }
            },
            {
              name: 'Celular 1',
              field: 'contacts.1.phone',
              sortable: false,
              element: item => {
                const result = item.contacts

                if (item.prospectQueryApis) {
                  if (!result) {
                    return <p></p>
                  }
                }

                const lemitPhone = result.pessoa?.celulares?.[0]

                if (!lemitPhone) {
                  return <p></p>
                }

                return (
                  <p>{phoneMask(`${lemitPhone.ddd}${lemitPhone.numero}`)}</p>
                )
              }
            },
            {
              name: 'Celular 2',
              field: 'contacts.2.phone',
              sortable: false,
              element: item => {
                const result = item.contacts

                if (!result) {
                  return <p></p>
                }

                const lemitPhone = result.pessoa?.celulares?.[1]

                if (!lemitPhone) {
                  return <p></p>
                }

                return (
                  <p>{phoneMask(`${lemitPhone.ddd}${lemitPhone.numero}`)}</p>
                )
              }
            },
            {
              name: 'Celular 3',
              field: 'contacts.3.phone',
              sortable: false,
              element: item => {
                const result = item.contacts

                if (!result) {
                  return <p></p>
                }

                const lemitPhone = result.pessoa?.celulares?.[2]

                if (!lemitPhone) {
                  return <p></p>
                }

                return (
                  <p>{phoneMask(`${lemitPhone.ddd}${lemitPhone.numero}`)}</p>
                )
              }
            }
          ]}
          actions={[
            {
              name: 'view',
              title: 'Visualizar',
              spanIcon: 'fa fa-search',
              linkTo: item => {
                return `/commercial/prospects/view/${convertStringToBase64(
                  item.id?.toString()
                )}`
              }
            }
          ]}
        />
      )}

      {modalExportProspect && (
        <Modal
          onClickButtonCancel={handleClickModalQueryOnClose}
          isOpenModal={modalExportProspect}
          pageTitle="Exportar prospects"
          styles={{ padding: '30px 5px', overflow: 'auto' }}
          Children={
            <FormExport
              isOpenInModal={{ handleOnClose: handleClickModalQueryOnClose }}
            />
          }
        />
      )}
    </Container>
  )
}
export default List
