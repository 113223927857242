import { PageLink } from '../../../../../../assets/layout/core'

export const breadcrumbCreate: PageLink[] = [
  {
    title: 'Campanha de distribuição',
    path: '#',
    isSeparator: false,
    isActive: false
  },
  {
    title: ' ',
    path: ' ',
    isSeparator: true,
    isActive: false
  }
]
