import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import PrivateRoutes from './Route'
import {
  SignIn,
  SignUp,
  Dashboard,
  Profile,
  Autenticate
} from './users/UsersRouter'
import { MenuRouter } from './config/MenuRouter'
import { RolesRouter } from './users/RolesRouter'
import { UsersRouter } from './config/UsersRouter'
import { PermissionsRouter } from './users/PermissionsRouter'
import { CacheRegisterRouter } from './cache/CacheRouter'
import { useAuth } from '../hooks/auth'
import { NotFoundPage } from '../pages/NotFound'
import { PermissionsAssociatedRouter } from './users/PermissionsAssociatedRouter'
import { IpAllowedRouter } from './users/IpAllowedRouter'
import { ProspectsRouter } from './commercial/ProspectsRoutes'
import { AgreementGroupsRouter } from './operational/AgreementGroupsRoutes'
import VerifyEmail from 'pages/Commercial/Partners/VerifyEmail'
import { ReportsRouter } from './commercial/ReportsRoutes'
import { PartnersRouter } from './commercial/PartnersRoutes'
import { QueryRouter } from './commercial/QueryRoutes'
import { UpdatePasswordSimRouter } from './commercial/UpdatePasswordSimRoutes'
import ProspectExportCredcesta from 'pages/ProspectExportCredcesta'
import { QueryBatchsRouter } from './commercial/QueryBatchRoutes'
import { ExportRouter } from './commercial/ExportRoutes'
import { DistributionCampaignsRouter } from './commercial/DistributionCampaignRoutes'

const Routes = () => {
  const { userLogged, backupHistory } = useAuth()
  return (
    <Switch>
      <Route path="/" exact component={SignIn}>
        {!backupHistory?.length && userLogged() && <Redirect to="/dashboard" />}
      </Route>

      <Route path="/signup" component={SignUp} />
      <Route path="/autenticate/:data" component={Autenticate} />
      <Route path="/prospect/credcesta" component={ProspectExportCredcesta} />

      <PrivateRoutes path="/dashboard" component={Dashboard} />
      <PrivateRoutes path="/users/profile" component={Profile} />

      <Route path="/users/menus" component={MenuRouter} />
      <Route path="/users/roles" component={RolesRouter} />
      <Route path="/users/ipAllowed" component={IpAllowedRouter} />
      <Route path="/users/permissions" component={PermissionsRouter} />
      <Route
        path="/users/permissionsAssociated"
        component={PermissionsAssociatedRouter}
      />
      <Route path="/users" component={UsersRouter} />

      <Route path="/commercial/export" component={ExportRouter} />
      <Route path="/commercial/queryBatchs" component={QueryBatchsRouter} />
      <Route path="/commercial/reports/partners" component={ReportsRouter} />
      <Route path="/commercial/partners/block" component={PartnersRouter} />
      <Route path="/commercial/prospects" component={ProspectsRouter} />
      <Route
        path="/commercial/distributionCampaign"
        component={DistributionCampaignsRouter}
      />
      <Route
        path="/commercial/partners/email/verify/validate/:token"
        component={VerifyEmail}
      />

      <Route
        path="/operational/agreementGroups"
        component={AgreementGroupsRouter}
      />

      <Route path="/query" component={QueryRouter} />
      <Route path="/updatePasswordSim" component={UpdatePasswordSimRouter} />

      <Route path="/cache" component={CacheRegisterRouter} />

      <Route path="*" component={NotFoundPage} />
    </Switch>
  )
}
export default Routes
