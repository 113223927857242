/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import Container from '../../../../components/Container'
import api from '../../../../services/api'
import { useToast } from '../../../../hooks/toast'
import { useLoading } from '../../../../hooks/loading'
import { Alert } from '../../../../components/Alert'
import { nameActions } from '../domain/info'
import { apiDelete, apiList } from '../domain/api'
import { breadcrumbView } from '../domain/breadcrumb'
import {
  toolsViewCreate,
  toolsViewDelete,
  toolsViewList,
  toolsViewUpdate
} from '../domain/tools'
import Tabs from '../../../../components/Tabs'
import Tab from '../../../../components/Tabs/Tab'
import DataTable from '../../../../components/DataTable'
import { headersDocuments, headersProspectQueryApi } from '../domain/headers'
import Modal from '../../../../components/Modal'
import { CustomModal } from '../components/CustomModal'
import { BRL, cnpjMask, cpfMask, phoneMask } from '../../../../utlis/mask'
import moment from 'moment'
import { ListSteps } from '../components/ListSteps'
import { FormQueryApi, TypePerson } from '../components/FormQueryApi'
import { useUpdateDataTable } from 'hooks/dataTable'
import { APIS_NAMES, LOCAL_STORAGE_KEYS, MASKS } from 'common/constants'
import {
  LEMIT_COLUMN_NAME_DDD,
  LEMIT_COLUMN_NAME_NUMBER,
  LEMIT_COLUMN_NAME_WHATSAPP,
  LEMIT_FIELD_PHONE,
  SIM_STATUS
} from 'common/constants/Commercial/Prospects'
import { FormHistoryProspect } from '../components/FormHistoryProspect'
import { useAuth } from 'hooks/auth'
import { CallbackDataTableResponse, callbackLoadDataProspect } from '../List'
import { handleErrorMessage } from 'utlis/handleErros'
import CustomDataTable from 'components/CustomDataTable'
import { convertBase64ToString, convertStringToBase64 } from 'utlis/base64'

interface ProspectData {
  id: number
  partner_id: number
  cpf: string
  name: string
  status: string
  birth_date: string
  admission_date: string
  created_at: string
  updated_at: string
  contacts?: {
    pessoa: {
      cpf: string
      nome: string
      sexo: string
      fixos: {
        ddd: number
        plus: boolean
        numero: string
        ranking: number
        whatsapp: boolean
      }[]
      renda: number
      carros: {
        placa: string
        marca: string
        ano_fabricacao: number
        ano_modelo: number
        renavan: string
        chassi: string
        data_licenciamento: string
        ranking: number
      }[]
      emails: {
        email: string
        ranking: number
        possui_cookie: boolean
      }[]
      falecido: boolean
      nome_mae: string
      ocupacao?: string
      vinculos: {
        cpf_vinculo: string
        nome_vinculo: string
        tipo_vinculo: string
      }[]
      celulares: {
        ddd: number
        plus: boolean
        numero: string
        ranking: number
        whatsapp: boolean
      }[]
      enderecos: {
        uf: string
        cep: string
        tipo: string
        bairro: string
        cidade: string
        numero: string
        ranking: number
        endereco: string
        logradouro: string
        complemento?: string
        tipo_logradouro: string
        titulo_logradouro?: string
      }[]
      situacao_cpf: string
      risco_credito: {
        cpf_cnpj: string
        score_credito: string
      }
      data_nascimento: string
      participacao_societaria: {
        nome: string
        cnpj: string
        capital_social: number
        participacao_socio: number
        data_fundacao: string
        situacao_cadastral: string
      }[]
    }
    data_consulta: string
  }
  agreementGroup: {
    name: string
  }
  partner: {
    person: { name: string }
  }
  agency?: string
  account?: string
  prospectPartners: {
    partner: Partner
  }[]
  prospectQueryApis: {
    result: any
    api_name: 'Lemit'
  }[]
  santander?: {
    commission: boolean
    margin: string
    refin: boolean
    registration: string
    query_date: string
  }
  credcesta?: {
    limitAvailable: string
  }
}

function convertToDecimal(money: string) {
  if (money?.toString().includes(',')) {
    return BRL(money).value
  }

  const parsedValue = Number(money)

  return BRL(parsedValue).value
}

const PROSPECT_QUERY_API_STATUS: any = {
  finished: 'Concluído'
}

type LemitPhoneApiResponse = {
  ddd: string
  numero: string
  whatsapp: string
  plus: string
}
type FormatDataLemitProps = {
  items: LemitPhoneApiResponse[]
}
async function handleVerifyIfNumberHasDoNotDisturb({
  number
}: {
  number: string
}) {
  try {
    const responseDoNotDisturb = await api.post(
      'https://api.cedibra.com/commercial/DoNotDisturb/findByParams',
      {
        phone: number
      }
    )
    return responseDoNotDisturb.data.doNotDisturb
  } catch (error) {}
}
async function formatDataLemit({ items }: FormatDataLemitProps) {
  const phones = []
  for (const item of items) {
    const doNotDisturb = await handleVerifyIfNumberHasDoNotDisturb({
      number: `${item.ddd}${item.numero}`
    })
    phones.push({
      ...item,
      doNotDisturb
    })
  }
  return phones
}

async function loadData({
  params,
  type
}: {
  params: any
  type: 'previous' | 'next'
}) {
  const response = await api.get('dataTable', {
    params
  })
  const items = response.data.items
  callbackLoadDataProspect({
    items,
    totalItems: response.data.totalItens,
    params
  })
  if (type === 'previous') {
    window.location.href = `/commercial/prospects/view/${convertStringToBase64(
      items[items.length - 1].id?.toString()
    )}`
    return
  }
  window.location.href = `/commercial/prospects/view/${convertStringToBase64(
    response.data.items[0].id?.toString()
  )}`
}

const SantanderInfo = ({ santander }: { santander: any }) => {
  if (!santander) {
    return null
  }

  return (
    <>
      <h3>Santander</h3>
      <CustomDataTable
        customItems={[{ ...santander }]}
        headers={[
          {
            name: 'Matricula',
            field: 'registration',
            sortable: false
          },
          {
            name: 'Margem para consignado',
            field: 'margin',
            sortable: false,
            custom: true
          },
          {
            name: 'Comissionado/Srcc',
            field: 'santander.commission',
            sortable: false,
            custom: true
          },
          {
            name: 'Refin santander',
            field: 'santander.refin',
            sortable: false,
            custom: true
          }
        ]}
        customHeaders={[
          {
            name: 'Margem para consignado',
            field: 'margin',
            sortable: false,
            element: item => {
              return <p>{BRL(convertToDecimal(item.margin)).format()}</p>
            }
          },
          {
            name: 'Comissionado/Srcc',
            field: 'santander.commission',
            sortable: false,
            element: item => {
              return <p>{item.commission ? 'Sim' : 'Não'}</p>
            }
          },
          {
            name: 'Refin santander',
            field: 'santander.refin',
            sortable: false,
            element: item => {
              return <p>{item.refin ? 'Sim' : 'Não'}</p>
            }
          }
        ]}
      />
    </>
  )
}

const CredcestaInfo = ({ credcesta }: { credcesta: any }) => {
  if (!credcesta) {
    return null
  }

  return (
    <>
      <h3>Banco Master</h3>
      <CustomDataTable
        customItems={[{ ...credcesta }]}
        headers={[
          {
            name: 'Limite disponível',
            field: 'limit_available',
            sortable: false,
            custom: true
          }
        ]}
        customHeaders={[
          {
            name: 'Limite disponível',
            field: 'limit_available',
            sortable: false,
            element: item => {
              return (
                <p>{BRL(convertToDecimal(item.limit_available)).format()}</p>
              )
            }
          }
        ]}
      />
    </>
  )
}

const View = (): JSX.Element => {
  const params = useParams<{ id: string }>()
  const id = useMemo(() => {
    if (!params?.id) {
      return null
    }
    return convertBase64ToString(params?.id)
  }, [params?.id])
  const { user } = useAuth()
  const history = useHistory()
  const location = useLocation()
  const { updateDataTable } = useUpdateDataTable()
  const [prospect, setProspect] = useState<ProspectData | null>(null)
  const { addToast } = useToast()
  const [searchParams, setSearchParams] = useState([{ prospect_id: `${id}` }])
  const [modalView, setModalView] = useState(false)
  const [modalQueryApi, setModalQueryApi] = useState(false)
  const [currentItemView, setCurrentItemView] = useState<any>({})
  const [modalCreate, setModalCreate] = useState(false)
  const [modalEdit, setModalEdit] = useState(false)
  const [currentItemUpdate, setCurrentItemUpdate] = useState<any>({} as any)
  const [lemitData, setLemitData] = useState<any[]>([])
  const dateOutFormat = 'DD/MM/YYYY'
  const searchParametersProspectHistory = useRef([
    { prospect_id: id, partner_id: user?.oldUser?.uid.toString() }
  ])
  const [alert, setIsActiveAlert] = useState<{
    isActive: boolean
    id: number
    name: string
  }>({
    id: 0,
    isActive: false,
    name: ''
  })

  const { activeLoading, disableLoading } = useLoading()
  const navigateToProspect = useCallback(
    async ({ type }: { type: 'next' | 'previous' }) => {
      try {
        const hasValues = JSON.parse(
          sessionStorage.getItem(LOCAL_STORAGE_KEYS.PROSPECT_HAS_HISTORY)
        )

        if (!hasValues) {
          addToast({
            title: 'Ação não permitida',
            description: 'Você precisa colocar alguma marcação ao cliente',
            type: 'info'
          })
          return
        }

        const prospectList = JSON.parse(
          sessionStorage.getItem(LOCAL_STORAGE_KEYS.PROSPECT_LIST)
        ) as CallbackDataTableResponse
        let nextIndex = prospectList.items.indexOf(Number(id))

        if (type === 'next') {
          nextIndex += 1
        } else if (type === 'previous') {
          nextIndex -= 1
        }

        if (nextIndex < 0) {
          if (prospectList.params.page > 1) {
            const params = {
              ...prospectList.params,
              page: prospectList.params.page - 1
            }
            await loadData({ params, type: 'previous' })
            return
          }
          addToast({
            title: 'Ação não permitida',
            description: 'Você esta no limite da lista de prospects',
            type: 'info'
          })
          return
        }
        if (nextIndex >= prospectList.items.length) {
          const params = {
            ...prospectList.params,
            page: prospectList.params.page + 1
          }
          if (params.page > prospectList.maxPage) {
            addToast({
              title: 'Ação não permitida',
              description: 'Você esta no limite da lista de prospects',
              type: 'info'
            })
            return
          }
          await loadData({ params, type: 'next' })
          return
        }

        const nextProspectId = prospectList.items[nextIndex]

        window.location.href = `/commercial/prospects/view/${convertStringToBase64(
          nextProspectId?.toString()
        )}`
      } catch (error) {
        console.error('Ocorreu um erro:', error)
      }
    },
    [addToast, id]
  )

  useEffect(() => {
    setSearchParams(oldParams => [
      {
        ...oldParams[0],
        partner_id: user?.oldUser?.uid
      }
    ])
  }, [user.id, user?.oldUser?.uid])

  const loadProspect = useCallback(async () => {
    activeLoading()
    try {
      const response = await api.get<ProspectData>(apiList(id))
      const { data } = response
      setProspect(data)
      const findLemitData = data?.prospectQueryApis?.find?.(
        item => item.api_name?.toUpperCase() === 'LEMIT'
      )
      if (findLemitData) {
        const phones = await formatDataLemit({
          items: findLemitData?.result?.pessoa?.celulares
        })
        setLemitData(phones)
      }
      disableLoading()
    } catch (err) {
      const [, baseUrl, path] = location.pathname.split('/')
      disableLoading()
      addToast({
        type: 'error',
        title: 'Error ao carregar a prospecção',
        description:
          'Houve um error ao carregar a prospecção, tente novamente mais tarde!'
      })
      if (path.includes('view')) {
        history.push(`/${baseUrl}`)
      } else {
        history.push(`/${baseUrl}/${path}`)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    loadProspect()
  }, [loadProspect])

  const formatsAvailable = useMemo(
    () => [
      'YYYY-MM-DD',
      'YYYY-MM-DD HH:mm:ss',
      'DD/MM/YYYY',
      'DD/MM/YYYY HH:mm:ss',
      'YYYY-MM-DDTHH:mm:ssZ',
      'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'
    ],
    []
  )

  const checkFormatDates = useCallback(
    (value: string) => {
      // eslint-disable-next-line consistent-return
      let formatValue = 'YYYY-MM-DD'
      formatsAvailable.forEach(format => {
        if (moment(value, format, true).isValid()) {
          formatValue = format
        }
      })
      return formatValue
    },
    [formatsAvailable]
  )

  const handlerClickButtonCancellAlert = () => {
    setIsActiveAlert({
      id: 0,
      isActive: false,
      name: ''
    })
    addToast({
      type: 'info',
      title: 'Operação cancelada.'
    })
  }

  const handlerClickButtonConfirmAlert = async (id: string) => {
    try {
      await api.delete(apiDelete(id))
      setIsActiveAlert({
        id: 0,
        isActive: false,
        name: ''
      })
      addToast({
        type: 'success',
        title: 'Usuário removido com sucesso.'
      })
    } catch (err) {
      setIsActiveAlert({
        id: 0,
        isActive: false,
        name: ''
      })
      addToast({
        type: 'error',
        title: 'Usuário não removido, pois ainda está sendo usada.'
      })
    }
  }

  const [alertRemoveParent, setAlertRemoveParent] = useState(false)

  const handleOnClickRemoveParent = () => {
    setAlertRemoveParent(true)
  }

  const handlerOnClickButtonConfirmRemoveParent = async (id: number) => {
    try {
      await api.delete(apiDelete(String(id)))
      setAlertRemoveParent(false)
      addToast({
        type: 'success',
        title: 'Usuário removido com sucesso.'
      })
      history.goBack()
    } catch (err: any) {
      setAlertRemoveParent(false)
      if (err.response.status === 403) {
        addToast({
          type: 'error',
          title: err.response.data.message
        })
        return
      }
      addToast({
        type: 'error',
        title: 'Usuário não removido, pois ainda está sendo usada.'
      })
    }
  }

  const handlerOnClickButtonCancelRemoveParent = () => {
    setAlertRemoveParent(false)
  }

  const handleClickOnClose = async () => {
    setModalView(false)
    setModalCreate(false)
    setModalEdit(false)
    updateDataTable()
    await loadProspect()
  }
  const handleClickModalQueryOnClose = async () => {
    await loadProspect()
    updateDataTable()
    setModalQueryApi(false)
  }
  const elementsRef = useRef<any>({})
  const formatingValues = useCallback(
    ({ key, value: inputValue }: { key: string; value: string | number }) => {
      const fieldsFormating = ['cpf', 'cnpj']
      const value = inputValue.toString()
      const findField = fieldsFormating.find(item => key.includes(item))
      if (inputValue.toString().match(/[0-9]/gi)) {
        if (findField) {
          if (findField === 'cpf') {
            return cpfMask(value)
          }
          if (findField === 'cnpj') {
            return cnpjMask(value)
          }
        }
      }
      if (value === 'true') {
        return 'Sim'
      }
      if (value === 'false') {
        return 'Não'
      }
      const format = checkFormatDates(value)
      if (moment(value, format, true).isValid()) {
        return moment(value, format).format(dateOutFormat)
      }
      return value
    },
    [checkFormatDates]
  )
  const serializedKey = useCallback((value: string) => {
    return value.split('_').join(' ')
  }, [])
  const renderElement = useCallback(
    ({ item, parentKey, parentData }): any => {
      if (item) {
        const dataEntries: any = Object.entries(item)
        for (const [key, value] of dataEntries) {
          if (typeof value === 'object') {
            if (Array.isArray(value)) {
              if (value.length) {
                renderElement({
                  parentData: value,
                  parentKey: serializedKey(key)
                })
              }
            } else {
              renderElement({ item: value, parentKey: serializedKey(key) })
            }
          } else {
            if (parentKey) {
              if (elementsRef.current[parentKey]) {
                elementsRef.current[parentKey] = {
                  ...elementsRef.current[parentKey],
                  child: [
                    ...elementsRef.current[parentKey].child,
                    {
                      title: serializedKey(key),

                      value: formatingValues({ key, value })
                    }
                  ]
                }
              } else {
                elementsRef.current[parentKey] = {
                  title: parentKey,

                  value: 'parent',
                  child: [
                    {
                      title: serializedKey(key),

                      value: formatingValues({ key, value })
                    }
                  ]
                }
              }
            } else {
              elementsRef.current[key] = {
                title: serializedKey(key),

                value: formatingValues({ key, value })
              }
            }
          }
        }
      } else if (parentData) {
        if (parentKey) {
          if (elementsRef.current[parentKey]) {
            elementsRef.current[parentKey] = {
              ...elementsRef.current[parentKey],
              type: 'array',
              child: [...elementsRef.current[parentKey].child, ...parentData]
            }
          } else {
            elementsRef.current[parentKey] = {
              title: parentKey,
              type: 'array',
              value: 'parent',
              child: [...parentData]
            }
          }
        }
      }
      return elementsRef.current
    },
    [formatingValues, serializedKey]
  )

  const handleClickOnOpenModalView = useCallback(
    async (currentItem: any) => {
      const apiNameLowerCase = currentItem.api_name?.toLowerCase()

      const verifyIfCurrentItemApiNameIsEqualToLemit =
        apiNameLowerCase === APIS_NAMES.LEMIT.toLowerCase()

      const verifyIfCurrentItemApiNameIsEqualToSim =
        apiNameLowerCase === APIS_NAMES.SIM.toLowerCase()

      const verifyIfCurrentItemApiNameIsEqualToCredcesta =
        apiNameLowerCase === APIS_NAMES.CREDCESTA.toLowerCase()

      const verifyIfCurrentItemApiNameIsEqualToFgts =
        apiNameLowerCase === APIS_NAMES.FGTS.toLowerCase()

      const copyItem = { ...JSON.parse(JSON.stringify(currentItem)) }

      if (verifyIfCurrentItemApiNameIsEqualToLemit) {
        const dataResultElements = renderElement({ item: copyItem?.result })
        const arrayElements = Object.values(dataResultElements) as any[]
        const findPhonesArray = arrayElements.findIndex(
          item => item.title.toLowerCase() === LEMIT_FIELD_PHONE
        )
        if (findPhonesArray !== -1) {
          const newPhoneArray = []
          for (const item of arrayElements[findPhonesArray].child) {
            const responseDoNotDisturb =
              await handleVerifyIfNumberHasDoNotDisturb({
                number: `${item?.[LEMIT_COLUMN_NAME_DDD]}${item?.[LEMIT_COLUMN_NAME_NUMBER]}`
              })
            newPhoneArray.push({ ...item, doNotDisturb: responseDoNotDisturb })
          }
          arrayElements[findPhonesArray].child = [...newPhoneArray]
        }
        copyItem.result = arrayElements
        elementsRef.current = {}
      }
      if (verifyIfCurrentItemApiNameIsEqualToSim) {
        copyItem.result = {
          steps: [...copyItem.result]
        }
        copyItem.result.api_name = currentItem.api_name
        copyItem.result.client = { ...prospect }
        copyItem.result.prospect_query_api_id = copyItem.id
        copyItem.result.query_date = copyItem.query_date
        copyItem.result.status = copyItem.status
        copyItem.result.created_at = copyItem.created_at
      }
      if (verifyIfCurrentItemApiNameIsEqualToCredcesta) {
        copyItem.result.api_name = currentItem.api_name
        copyItem.result.prospect = { ...prospect }
        copyItem.result.prospect_query_api_id = copyItem.id
        copyItem.result.status = copyItem.status
        copyItem.result.created_at = copyItem.created_at
      }
      if (verifyIfCurrentItemApiNameIsEqualToFgts) {
        copyItem.result.api_name = currentItem.api_name
        copyItem.result.prospect = { ...prospect }
        copyItem.result.prospect_query_api_id = copyItem.id
        copyItem.result.status = copyItem.status
        copyItem.result.created_at = copyItem.created_at
      }
      setCurrentItemView(copyItem)
      setModalView(true)
    },
    [prospect, renderElement]
  )
  const handleClickOnOpenModalQuery = useCallback(async () => {
    setModalQueryApi(true)
  }, [])

  const handleClickOnOpenModalCreate = () => {
    setModalCreate(true)
  }

  const handlerOnClickButtonEditInCurrentRow = (currentValue: any) => {
    setCurrentItemUpdate(currentValue)
    setModalEdit(true)
  }

  const handleDownload = useCallback(
    async ({ id, filename }: { id: number; filename: string }) => {
      try {
        const responseFileUrl = await api.post(
          `/commercial/prospectHistories/download/${id}`,
          {
            filename
          }
        )
        const anchorElement = document.createElement('a')
        anchorElement.href = responseFileUrl.data
        anchorElement.setAttribute('target', '_blank')
        document.body.appendChild(anchorElement)
        anchorElement.click()
        anchorElement.remove()
      } catch (error) {
        console.log(error)
      }
    },
    []
  )

  const handleGetProspectHistories = useCallback(({ items }) => {
    sessionStorage.setItem(
      LOCAL_STORAGE_KEYS.PROSPECT_HAS_HISTORY,
      JSON.stringify(!!items.length)
    )
  }, [])
  const queryLemit = useCallback(async () => {
    activeLoading()
    try {
      const responseLemit = await api.post('/apis/lemit/query', {
        document: prospect?.cpf,
        prospect_id: prospect?.id,
        type: TypePerson.PF
      })
      const phones = await formatDataLemit({
        items: responseLemit.data?.pessoa?.celulares
      })
      setLemitData(phones)
      addToast({
        type: 'success',
        title: 'Registro criado',
        description: 'Registro criado com sucesso'
      })
    } catch (error: any) {
      addToast({
        ...handleErrorMessage(error)
      })
    }
    disableLoading()
  }, [activeLoading, addToast, disableLoading, prospect?.cpf, prospect?.id])

  const mountTools = useCallback(() => {
    let defaultTools = [
      toolsViewUpdate(String(id)),
      toolsViewDelete(() => {
        handleOnClickRemoveParent()
      }),
      toolsViewCreate(),
      toolsViewList(),
      {
        icon: 'fa fa-chevron-left',
        name: 'Anterior',
        to: '',
        color: 'btn-success',
        style: { marginLeft: '50px' },
        handleOnClick: () => navigateToProspect({ type: 'previous' })
      },
      {
        iconRight: 'fa fa-chevron-right ms-1',
        name: 'Proximo',
        to: '',
        color: 'btn-success',
        handleOnClick: () => navigateToProspect({ type: 'next' })
      }
    ]
    if (user?.oldUser?.uid) {
      defaultTools = [
        toolsViewList(),
        {
          icon: 'fa fa-chevron-left',
          name: 'Anterior',
          to: '',
          color: 'btn-success',
          style: { marginLeft: '50px' },
          handleOnClick: () => navigateToProspect({ type: 'previous' })
        },
        {
          iconRight: 'fa fa-chevron-right ms-1',
          name: 'Proximo',
          to: '',
          color: 'btn-success',
          handleOnClick: () => navigateToProspect({ type: 'next' })
        }
      ]
      if (
        user?.partner?.crm_query_lemit &&
        user?.role?.team?.toLowerCase() === 'p'
      ) {
        defaultTools.push({
          icon: '',
          name: 'CONSULTA LEMIT',
          to: '',
          color: 'btn-success',
          handleOnClick: () => queryLemit()
        })
      }
    }
    return defaultTools
  }, [
    id,
    navigateToProspect,
    queryLemit,
    user?.oldUser?.uid,
    user?.partner?.crm_query_lemit,
    user?.role?.team
  ])

  return (
    <>
      <Container
        pageTitle={nameActions.read.name}
        breadcrumb={breadcrumbView}
        tools={mountTools()}
      >
        <div className="form-body">
          <div className="row mb-2">
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="name" className=" fw-bold fs-6">
                  Nome
                </label>
                <p className=" fs-6 text-gray-800">{prospect?.name}</p>
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="role_id" className=" fw-bold fs-6">
                  CPF
                </label>
                <p className=" fs-6 text-gray-800">{prospect?.cpf}</p>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="updated" className=" fw-bold fs-6">
                  Grupo de Convênio
                </label>
                <p className=" fs-6 text-gray-800">
                  {prospect?.agreementGroup?.name}
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="updated" className=" fw-bold fs-6">
                  Parceiro
                </label>
                <p className=" fs-6 text-gray-800">
                  {prospect?.prospectPartners?.[0]?.partner?.person?.name}
                </p>
              </div>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="updated" className=" fw-bold fs-6">
                  Data de nascimento
                </label>
                <p className=" fs-6 text-gray-800">{prospect?.birth_date}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="updated" className=" fw-bold fs-6">
                  Data de admissão
                </label>
                <p className=" fs-6 text-gray-800">
                  {prospect?.admission_date}
                </p>
              </div>
            </div>
            {!!prospect?.agency && (
              <div className="col-md-2">
                <div className="form-group">
                  <label htmlFor="updated" className=" fw-bold fs-6">
                    Agencia
                  </label>
                  <p className=" fs-6 text-gray-800">{prospect?.agency}</p>
                </div>
              </div>
            )}
            {!!prospect?.account && (
              <div className="col-md-2">
                <div className="form-group">
                  <label htmlFor="updated" className=" fw-bold fs-6">
                    Conta
                  </label>
                  <p className=" fs-6 text-gray-800">{prospect?.account}</p>
                </div>
              </div>
            )}
            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="updated" className=" fw-bold fs-6">
                  Status
                </label>
                <p className=" fs-6 text-gray-800">{prospect?.status}</p>
              </div>
            </div>
          </div>

          <SantanderInfo santander={prospect?.santander} />

          <CredcestaInfo credcesta={prospect?.credcesta} />

          {!!lemitData.length && (
            <div className="row mt-5">
              <CustomDataTable
                customItems={lemitData}
                headers={[
                  {
                    field: 'plus',
                    name: 'Plus',
                    sortable: false
                  },
                  {
                    field: 'numero',
                    name: 'Número',
                    sortable: false,
                    custom: true
                  },
                  {
                    field: 'ranking',
                    name: 'Ranking',
                    sortable: false
                  },
                  {
                    field: 'whatsapp',
                    name: 'Whatsapp',
                    sortable: false
                  }
                ]}
                customHeaders={[
                  {
                    name: 'Número',
                    field: 'numero',
                    sortable: true,
                    element: item => {
                      const fullNumber = `${item?.[LEMIT_COLUMN_NAME_DDD]}${item?.[LEMIT_COLUMN_NAME_NUMBER]}`
                      return (
                        <p>
                          {item?.doNotDisturb && (
                            <button
                              className="btn py-0"
                              style={{ cursor: 'default' }}
                            >
                              <span className="fa-solid fa-ban fa-xl text-danger"></span>
                            </button>
                          )}
                          {phoneMask(fullNumber)}

                          {!!item?.[LEMIT_COLUMN_NAME_WHATSAPP] && (
                            <button
                              className="btn py-0"
                              disabled={item?.doNotDisturb}
                              onClick={() => {
                                const a = document.createElement('a')
                                a.href = `https://api.whatsapp.com/send?phone=${fullNumber}&text=`
                                a.target = '_blank'
                                a.click()
                                a.remove()
                              }}
                            >
                              <span
                                className={`fab fa-whatsapp fa-xl ${
                                  item?.doNotDisturb ? '' : ' text-primary'
                                }`}
                              ></span>
                            </button>
                          )}
                        </p>
                      )
                    }
                  }
                ]}
              />
            </div>
          )}
        </div>
        <div className="row">
          <div className="">
            <Tabs>
              <Tab title="Histórico" key={0}>
                <div className="p-9">
                  <div className="card-header p-0">
                    <div className="card-title m-0 fw-bolder fs-6 col">
                      <p>Listagem</p>
                    </div>
                    <div
                      className="d-flex align-items-center text-primary"
                      onClick={handleClickOnOpenModalCreate}
                      style={{ cursor: 'pointer' }}
                    >
                      <span className="fa fa-plus " />
                      <span className="ms-2">Adicionar</span>
                    </div>
                  </div>
                  <div className="portlet-body form mt-5">
                    <DataTable
                      format={{ orderBy: '' }}
                      source="prospectHistory"
                      entity="ProspectHistory"
                      headers={headersDocuments}
                      callbackLoadData={handleGetProspectHistories}
                      searchParameters={searchParametersProspectHistory.current}
                      customHeaders={[
                        {
                          name: 'Anexos',
                          field: 'files',
                          sortable: false,
                          element: item => {
                            const files = item?.files

                            if (!files) {
                              return <p></p>
                            }

                            return (
                              <div>
                                {files?.map((file: any) => (
                                  <div
                                    key={file.filename}
                                    onClick={() =>
                                      handleDownload({
                                        id: item.id,
                                        filename: file.filename
                                      })
                                    }
                                    className="d-flex align-items-center text-primary cursor-pointer"
                                  >
                                    <span className="fa fa-download me-2"></span>
                                    <p>{file.filename}</p>
                                  </div>
                                ))}
                              </div>
                            )
                          }
                        },
                        {
                          name: 'Ações',
                          field: 'actions',
                          sortable: false,
                          element: item => {
                            if (item?.created_by === user.id) {
                              return (
                                <div className="d-flex justify-content-center">
                                  <span
                                    style={{ cursor: 'pointer' }}
                                    className="fa fa-edit text-primary me-2 pointer"
                                    onClick={() => {
                                      handlerOnClickButtonEditInCurrentRow(item)
                                    }}
                                  ></span>
                                </div>
                              )
                            }
                            return <p></p>
                          }
                        },
                        {
                          name: 'Agendado Para',
                          field: 'scheduled_at',
                          sortable: true,
                          element: item => {
                            if (item?.scheduled_at) {
                              return (
                                <p>
                                  {moment(
                                    item.scheduled_at,
                                    MASKS.DATE.LOCALE
                                  ).format(MASKS.DATE.LOCALE)}
                                </p>
                              )
                            }
                            return <p></p>
                          }
                        },
                        {
                          name: 'Cadastrado em',
                          field: 'created_at',
                          sortable: true,
                          element: item => {
                            if (item?.created_at) {
                              return (
                                <p>
                                  {moment(
                                    item.created_at,
                                    MASKS.DATE.LOCALE_WITH_TIME
                                  )
                                    .subtract(6, 'hours')
                                    .format(MASKS.DATE.LOCALE_WITH_TIME)}
                                </p>
                              )
                            }
                            return <p></p>
                          }
                        }
                      ]}
                    />
                  </div>
                </div>
              </Tab>
              <Tab key={1} title="Bancos">
                <div className="p-9">
                  <div className="card-header p-0">
                    <div className="card-title m-0 fw-bolder fs-6 col">
                      <p>Listagem</p>
                    </div>
                    {!!user?.oldUser?.uid && (
                      <div
                        className="d-flex align-items-center text-primary"
                        onClick={handleClickOnOpenModalQuery}
                        style={{ cursor: 'pointer' }}
                      >
                        <span className="fa fa-plus " />
                        <span className="ms-2">Consultar Banco</span>
                      </div>
                    )}
                  </div>
                  <div className="">
                    <DataTable
                      format={{ orderBy: 'api_name' }}
                      source="prospectQueryApi"
                      entity="ProspectQueryApi"
                      orderBySort="DESC"
                      notHasChildren
                      onlyView
                      headers={headersProspectQueryApi}
                      searchParameters={searchParams}
                      actions={[
                        {
                          name: 'view',
                          title: 'Visualizar',
                          spanIcon: 'fa fa-search',
                          onClick: item => handleClickOnOpenModalView(item)
                        }
                      ]}
                      customHeaders={[
                        {
                          name: 'Etapa',
                          field: 'result.0.title',
                          sortable: true,
                          element: item => {
                            if (Array.isArray(item.result)) {
                              const resultsQueryApiLength = item.result.length
                              return (
                                <p>
                                  {
                                    item?.result?.[resultsQueryApiLength - 1]
                                      ?.title
                                  }
                                </p>
                              )
                            }
                            return <p>{item?.result?.title}</p>
                          }
                        },
                        {
                          name: 'Status',
                          field: 'status',
                          sortable: true,
                          element: item => {
                            if (item.status) {
                              return (
                                <p>
                                  {PROSPECT_QUERY_API_STATUS[item.status] ||
                                    item.status}
                                </p>
                              )
                            }
                            return <p>{item.status}</p>
                          }
                        }
                      ]}
                    />
                  </div>
                </div>
              </Tab>
              <></>
            </Tabs>
          </div>
        </div>
      </Container>
      <Alert
        message={`Tem certeza que deseja excluir o registro ${alert.name} ?`}
        onClickCancellButton={handlerClickButtonCancellAlert}
        onClickConfirmButton={() => {
          handlerClickButtonConfirmAlert(String(alert.id))
        }}
        isActive={alert.isActive}
      />
      <Alert
        message={`Tem certeza que deseja excluir o registro ${prospect?.name} ?`}
        onClickCancellButton={handlerOnClickButtonCancelRemoveParent}
        onClickConfirmButton={() =>
          handlerOnClickButtonConfirmRemoveParent(Number(prospect?.id))
        }
        isActive={alertRemoveParent}
      />
      {modalView && (
        <Modal
          onClickButtonCancel={handleClickOnClose}
          isOpenModal={modalView}
          pageTitle={'Visualizar'}
          styles={{ padding: '30px 5px', overflow: 'auto' }}
          Children={
            currentItemView?.status &&
            currentItemView?.api_name?.toLowerCase() ===
              APIS_NAMES.SIM.toLowerCase() &&
            (currentItemView?.status?.toLowerCase() ===
              SIM_STATUS.WITH_PENDING.toLowerCase() ||
              currentItemView?.status?.toLowerCase() ===
                SIM_STATUS.PENDING.toLowerCase()) ? (
              <ListSteps
                isOpenInModal={{
                  handleOnClose: handleClickOnClose,
                  idParent: Number(currentItemView?.id)
                }}
                initialValues={currentItemView.result}
              />
            ) : (
              <CustomModal
                isOpenInModal={{
                  handleOnClose: handleClickOnClose
                }}
                type={currentItemView.api_name}
                status={currentItemView?.status}
                initialValues={currentItemView.result}
              />
            )
          }
        />
      )}
      {modalQueryApi && (
        <Modal
          onClickButtonCancel={handleClickModalQueryOnClose}
          isOpenModal={modalQueryApi}
          pageTitle="Consultar Banco"
          styles={{ padding: '30px 5px', overflow: 'auto' }}
          Children={
            <FormQueryApi
              initialValues={prospect}
              isOpenInModal={{ handleOnClose: handleClickModalQueryOnClose }}
            />
          }
        />
      )}
      {modalCreate && (
        <Modal
          onClickButtonCancel={handleClickOnClose}
          isOpenModal={modalCreate}
          pageTitle={'Adicionar Histórico'}
          styles={{ padding: '30px 250px' }}
          Children={
            <FormHistoryProspect
              typeForm="create"
              isOpenInModal={{
                handleOnClose: handleClickOnClose,
                idParent: Number(id)
              }}
            />
          }
        />
      )}
      {modalEdit && (
        <Modal
          onClickButtonCancel={handleClickOnClose}
          isOpenModal={modalEdit}
          pageTitle={'Editar Histórico'}
          styles={{ padding: '30px 250px' }}
          Children={
            <FormHistoryProspect
              typeForm="update"
              initialValues={{
                ...currentItemUpdate
              }}
              isOpenInModal={{
                handleOnClose: handleClickOnClose,
                idParent: Number(id)
              }}
            />
          }
        />
      )}
    </>
  )
}

export default View
