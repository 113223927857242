import React from 'react'
import Container from '../../../../components/Container'
import { FormDistributionCampaign } from '../components/Form'
import { nameActions } from '../domain/info'
import { breadcrumbCreate } from '../domain/breadcrumb'

const Create = (): JSX.Element => (
  <Container
    pageTitle={nameActions.create.name}
    breadcrumb={breadcrumbCreate}
    tools={[]}
  >
    <FormDistributionCampaign />
  </Container>
)

export default Create
