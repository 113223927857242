export const headers = [
  { name: 'Convênio', field: 'agreementGroup.name', sortable: true },
  { name: 'Nome', field: 'name', sortable: true, custom: true },
  { name: 'CPF', field: 'cpf', sortable: true },
  {
    name: 'Data de Nascimento',
    field: 'birth_date',
    sortable: true,
    custom: true
  },
  {
    name: 'Data de Admissão',
    field: 'admission_date',
    sortable: true,
    custom: true
  },
  {
    name: 'Celular 1',
    field: 'contacts.1.phone',
    sortable: false,
    custom: true
  },
  {
    name: 'Celular 2',
    field: 'contacts.2.phone',
    sortable: false,
    custom: true
  },
  {
    name: 'Celular 3',
    field: 'contacts.3.phone',
    sortable: false,
    custom: true
  },
  {
    name: 'Status de atendimento',
    field: 'status',
    sortable: true,
    custom: true
  },
  {
    name: 'Limite disponível Banco Master',
    field: 'limit_credcesta',
    sortable: false,
    custom: true
  },
  {
    name: 'Limite disponível Santander margem',
    field: 'limit_santander_margem',
    sortable: false,
    custom: true
  },
  { name: 'Ações', field: 'actions', sortable: false }
]
export const headersProspectQueryApi = [
  { name: 'Nome', field: 'api_name', sortable: true },
  { name: 'Data Consulta', field: 'query_date', sortable: true },
  { name: 'Etapa', field: 'result.0.title', sortable: true, custom: true },
  { name: 'Status', field: 'status', sortable: true, custom: true },
  { name: 'Ações', field: 'actions', sortable: false }
]
export const headersDocuments = [
  { name: 'Status', field: 'status', sortable: true },
  { name: 'Arquivo', field: 'files', sortable: true, custom: true },
  { name: 'Observações', field: 'description', sortable: true },
  {
    name: 'Agendado Para',
    field: 'scheduled_at',
    sortable: true,
    custom: true
  },
  {
    name: 'Cadastrador por',
    field: 'createdUser.name',
    sortable: true
  },
  { name: 'Cadastrado em', field: 'created_at', sortable: true, custom: true },
  { name: 'Ações', field: 'actions', sortable: false, custom: true }
]
export const headersDashboardProspect = [
  { name: 'CAMPANHA', field: 'distribution_campaign_name', sortable: true },
  { name: 'CONVÊNIO', field: 'agreement_group_name', sortable: true },
  { name: 'TOTAL DE PROSPECTS', field: 'total', sortable: true },
  {
    name: 'TOTAL DE PROSPECTS JÁ TRABALHADOS',
    field: 'totalWorked',
    sortable: true
  },
  {
    name: 'TOTAL DE PROSPECTS NÃO TRABALHADOS',
    field: 'totalNotWorked',
    sortable: true
  },
  { name: 'AÇÕES', field: 'actions', sortable: false }
]
