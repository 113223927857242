import React, { useCallback, useEffect, useState } from 'react'
import api from '../../../../../services/api'
import Form, { Input } from '../../../../../components/Form'
import { useHistory } from 'react-router-dom'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { useUpdateDataTable } from '../../../../../hooks/dataTable'
import { apiCreate } from '../../domain/api'
import { nameActions } from '../../domain/info'
import { SPACING_ROW_FORM } from 'common/constants'
import { Datalist } from 'components/Datalist'
import { Loading } from 'components/Loading'
import { AxiosResponse } from 'axios'
type IsOpenInModalProps = {
  idParent: number
  handleOnClose: () => void
}

type ReconciliationPartnerData = {
  items: {
    agreement_group_id?: string
    quantity?: string
  }[]
}

export enum ApisOptions {
  SIM = 1,
  LEMIT = 2,
  CREDCESTA = 3,
  FGTS = 4
}

type TypesFormProps = {
  isOpenInModal?: false | IsOpenInModalProps
  initialValues?: ReconciliationPartnerData & {
    idUpdate: number
  }
  typeForm: 'create' | 'update'
}

async function processQueue(tasks: (() => Promise<AxiosResponse<any>>)[]) {
  for await (const task of tasks) {
    await task()
  }
}

export const FormReconciliationPartner = ({
  initialValues
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const history = useHistory()
  const { updateDataTable } = useUpdateDataTable()

  const [defaultValues, setDefaultValues] =
    useState<ReconciliationPartnerData>()
  const [isLoading, setIsLoading] = useState(false)
  // const [selectedApi, setSelectedApi] = useState<string>('')
  // const [selectedApis, setSelectedApis] = useState<number[]>([1, 2, 3, 4])
  const [agreementGroups, setAgreementGroups] = useState<
    {
      name: string
      value: number
      id: number
    }[]
  >([
    {
      name: '',
      value: 0,
      id: 0
    }
  ])
  const [partners, setPartners] = useState<
    {
      name: string
      value: number
      id: number
    }[]
  >([
    {
      name: '',
      value: 0,
      id: 0
    }
  ])
  const [agreementGroupValue, setAgreementGroupValue] = useState({
    name: '',
    value: 0,
    id: 0
  })
  const [partnerValue, setPartnerValue] = useState({
    name: '',
    value: 0,
    id: 0
  })
  const [agreementGroupValue02, setAgreementGroupValue02] = useState({
    name: '',
    value: 0,
    id: 0
  })
  const [partnerValue02, setPartnerValue02] = useState({
    name: '',
    value: 0,
    id: 0
  })
  const [agreementGroupValue03, setAgreementGroupValue03] = useState({
    name: '',
    value: 0,
    id: 0
  })
  const [partnerValue03, setPartnerValue03] = useState({
    name: '',
    value: 0,
    id: 0
  })
  const [agreementGroupValue04, setAgreementGroupValue04] = useState({
    name: '',
    value: 0,
    id: 0
  })
  const [partnerValue04, setPartnerValue04] = useState({
    name: '',
    value: 0,
    id: 0
  })
  const [agreementGroupValue05, setAgreementGroupValue05] = useState({
    name: '',
    value: 0,
    id: 0
  })
  const [partnerValue05, setPartnerValue05] = useState({
    name: '',
    value: 0,
    id: 0
  })

  useEffect(() => {
    if (initialValues) {
      setDefaultValues({
        ...initialValues
      })
    }
  }, [initialValues])

  const { activeLoading, disableLoading } = useLoading()

  const getAgreementGroups = useCallback(async () => {
    activeLoading()
    const response = await api.get('/operational/agreementGroups/onlyWIthCode')
    const agreementGroupsSelect = response.data?.map(
      (agreementGroup: { id: number; name: string }) => ({
        value: agreementGroup.id,
        name: agreementGroup.name,
        id: agreementGroup.id
      })
    )
    setAgreementGroups(agreementGroupsSelect)
  }, [activeLoading])

  const getPartners = useCallback(async () => {
    activeLoading()
    try {
      const partnersResponse = await api.get('/commercial/partners')
      const filterPartners = partnersResponse.data?.filter(
        (partner: { id: number; person: { name: string }; active: boolean }) =>
          partner.active
      )
      const partnersSelect = filterPartners?.map(
        (partner: {
          id: number
          store_name: string
          person_cnpj_cpf: string
          active: boolean
        }) =>
          partner.active && {
            value: partner.id,
            name: `${partner.store_name} - ${partner.person_cnpj_cpf}`,
            id: partner.id
          }
      )
      setPartners(partnersSelect)
      disableLoading()
    } catch (error: any) {
      const statusCode = error?.response.status
      const [, baseUrl, path] = location.pathname.split('/')
      disableLoading()
      addToast({
        type: 'error',
        title:
          statusCode === 403
            ? 'Você não tem permissão'
            : 'Error ao carregar os parceiros',
        description:
          statusCode === 403
            ? 'Você não tem permissão para acessar essa página'
            : 'Houve um error ao carregar os parceiros, tente novamente mais tarde!'
      })
      if (path.includes('update')) {
        history.push(`/${baseUrl}`)
      } else {
        history.push(`/${baseUrl}/${path}`)
      }
    }
  }, [activeLoading, addToast, disableLoading, history])

  useEffect(() => {
    getAgreementGroups()
  }, [getAgreementGroups])

  useEffect(() => {
    getPartners()
  }, [getPartners])

  const onSubmitForm = async (data: ReconciliationPartnerData) => {
    setIsLoading(true)
    const createPromises = []
    if (data.items[0].quantity && agreementGroupValue?.id && partnerValue?.id) {
      createPromises.push(() =>
        api.post(apiCreate(), {
          quantity: data.items[0].quantity,
          agreement_group_id: agreementGroupValue?.id,
          partner_id: partnerValue?.id
        })
      )
    }
    if (
      data.items[1].quantity &&
      agreementGroupValue02?.id &&
      partnerValue02?.id
    ) {
      createPromises.push(() =>
        api.post(apiCreate(), {
          quantity: data.items[1].quantity,
          agreement_group_id: agreementGroupValue02?.id,
          partner_id: partnerValue02?.id
        })
      )
    }
    if (
      data.items[2].quantity &&
      agreementGroupValue03?.id &&
      partnerValue03?.id
    ) {
      createPromises.push(() =>
        api.post(apiCreate(), {
          quantity: data.items[2].quantity,
          agreement_group_id: agreementGroupValue03?.id,
          partner_id: partnerValue03?.id
        })
      )
    }
    if (
      data.items[3].quantity &&
      agreementGroupValue04?.id &&
      partnerValue04?.id
    ) {
      createPromises.push(() =>
        api.post(apiCreate(), {
          quantity: data.items[3].quantity,
          agreement_group_id: agreementGroupValue04?.id,
          partner_id: partnerValue04?.id
        })
      )
    }
    if (
      data.items[4].quantity &&
      agreementGroupValue05?.id &&
      partnerValue05?.id
    ) {
      createPromises.push(() =>
        api.post(apiCreate(), {
          quantity: data.items[4].quantity,
          agreement_group_id: agreementGroupValue05?.id,
          partner_id: partnerValue05?.id
        })
      )
    }
    if (!createPromises.length) {
      addToast({
        type: 'info',
        title: 'Preencha algum dado',
        description: 'Você precisa preencher algum dados para distribuir'
      })
      return
    }

    try {
      await processQueue(createPromises)
      updateDataTable()
      addToast({
        type: 'success',
        title: 'Distribuindo prospects',
        description: 'A distribuição dos prospects iniciado com sucesso'
      })
      setTimeout(() => {
        history.push(nameActions.read.to)
      }, 1000)
    } catch (error: any) {
      addToast({
        type: 'error',
        title: 'Erro ao adicionar o registro',
        description:
          error.message || 'Ocorreu um erro ao distribuir os prospects'
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Loading isActive={isLoading} />
      <Form onSubmit={onSubmitForm} defaultValues={defaultValues}>
        <div className={'row ' + 'mb-' + SPACING_ROW_FORM}>
          <Datalist
            className="col-3"
            data={agreementGroups}
            label="Grupo de Convênio"
            setValue={setAgreementGroupValue}
            value={agreementGroupValue}
          />
          <Datalist
            className="col-3"
            data={partners}
            label="Parceiro"
            setValue={setPartnerValue}
            value={partnerValue}
          />
          <Input name="items.0.quantity" label="Quantidade" className="col-2" />
        </div>
        <div className={'row ' + 'mb-' + SPACING_ROW_FORM}>
          <Datalist
            className="col-3"
            data={agreementGroups}
            label="Grupo de Convênio"
            setValue={setAgreementGroupValue02}
            value={agreementGroupValue02}
          />
          <Datalist
            className="col-3"
            data={partners}
            label="Parceiro"
            setValue={setPartnerValue02}
            value={partnerValue02}
          />
          <Input name="items.1.quantity" label="Quantidade" className="col-2" />
        </div>
        <div className={'row ' + 'mb-' + SPACING_ROW_FORM}>
          <Datalist
            className="col-3"
            data={agreementGroups}
            label="Grupo de Convênio"
            setValue={setAgreementGroupValue03}
            value={agreementGroupValue03}
          />
          <Datalist
            className="col-3"
            data={partners}
            label="Parceiro"
            setValue={setPartnerValue03}
            value={partnerValue03}
          />
          <Input name="items.2.quantity" label="Quantidade" className="col-2" />
        </div>
        <div className={'row ' + 'mb-' + SPACING_ROW_FORM}>
          <Datalist
            className="col-3"
            data={agreementGroups}
            label="Grupo de Convênio"
            setValue={setAgreementGroupValue04}
            value={agreementGroupValue04}
          />
          <Datalist
            className="col-3"
            data={partners}
            label="Parceiro"
            setValue={setPartnerValue04}
            value={partnerValue04}
          />
          <Input name="items.3.quantity" label="Quantidade" className="col-2" />
        </div>
        <div className={'row ' + 'mb-' + SPACING_ROW_FORM}>
          <Datalist
            className="col-3"
            data={agreementGroups}
            label="Grupo de Convênio"
            setValue={setAgreementGroupValue05}
            value={agreementGroupValue05}
          />
          <Datalist
            className="col-3"
            data={partners}
            label="Parceiro"
            setValue={setPartnerValue05}
            value={partnerValue05}
          />
          <Input name="items.4.quantity" label="Quantidade" className="col-2" />
        </div>
        {/* <div className="row">
        <Select
          name=""
          label="Escolha quais bancos deseja consultar"
          options={[
            {
              name: 'Sim',
              value: ApisOptions.SIM
            },
            {
              name: 'Lemit',
              value: ApisOptions.LEMIT
            },
            {
              name: 'Credcesta',
              value: ApisOptions.CREDCESTA
            },
            {
              name: 'Fgts',
              value: ApisOptions.FGTS
            }
          ]}
          value={selectedApi}
          onChange={event => {
            setSelectedApi(event.target.value)
          }}
          blank
          className="col-3"
          controlled
        />
        <div className="col-3 d-flex align-items-end">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              setSelectedApis(oldSelectedApis => [
                ...oldSelectedApis,
                Number(selectedApi)
              ])
              setSelectedApi('')
            }}
          >
            Adicionar
          </button>
        </div>
      </div>

      {selectedApis.map(
        apiSelected =>
          apiSelected && (
            <div className="row mt-5" key={apiSelected}>
              <Input
                className="col-3"
                name=""
                value={apisNames[apiSelected]}
                disabled
              />
              <div className="col-3">
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => {
                    const filteredApis = selectedApis.filter(
                      item => item !== apiSelected
                    )
                    setSelectedApis(filteredApis)
                  }}
                >
                  Remover
                </button>
              </div>
            </div>
          )
      )} */}

        <div className="mt-2 ard-footer d-flex justify-content-end py-6 ps-9 pe-0">
          <button type="submit" className="btn btn-primary">
            Distribuir
          </button>
        </div>
      </Form>
    </>
  )
}
