import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import TableHeader from './Header'
import Pagination from './Pagination'
import Search from './Search'
import { Container, LinkContainer } from './style'
import { useUpdateDataTable } from '../../hooks/dataTable'
import { useLoading } from '../../hooks/loading'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import convertDateToISO from '../../utlis/convertDateToISO'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

interface Action {
  name: string
  icon?: IconProp
  spanIcon?: string
  htmlIcon?: JSX.Element
  title: string
  link?: string
  onClick?: (params: any) => void
  linkTo?: (params: any) => string
}

interface Header {
  name: string
  field: string
  sortable: boolean
  custom?: boolean
  searchable?: boolean
  element?: (item: any) => JSX.Element
}

type OnChangePageProps = {
  page: number
  perPage: number
}

interface DataTableProps {
  headers?: Header[]
  customHeaders?: Header[]
  actions?: Action[]
  search?: boolean
  pagination?: boolean
  itemPerPage?: boolean
  viewField?: { source: string; field: string }
  customItems: any[]
  onChangePage?: ({ page, perPage }: OnChangePageProps) => Promise<void>
  currentTotalItems?: number
  customCurrentPage?: number
}

function getWindowSize() {
  const { innerHeight } = window
  return innerHeight
}

const CustomDataTable = ({
  headers,
  search,
  pagination,
  customHeaders,
  actions,
  onChangePage,
  currentTotalItems,
  itemPerPage,
  customItems,
  customCurrentPage
}: DataTableProps): JSX.Element => {
  const [items, setItems] = useState<any[]>([])
  const [filterItems, setFilterItems] = useState([])
  const [totalItems, setTotalItems] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [ItemsPerPage, setItemsPerPage] = useState(200)
  const [minPages, setMinPages] = useState(1)
  const [maxPages, setMaxPages] = useState(5)
  const tableContainerRef = useRef(null)
  const [hasTopPagination, setHasTopPagination] = useState(false)
  const [windowSize, setWindowSize] = useState(getWindowSize())
  const { isUpdated } = useUpdateDataTable()

  const { activeLoading, disableLoading } = useLoading()

  useEffect(() => {
    if (currentTotalItems) {
      setTotalItems(currentTotalItems)
    }
  }, [currentTotalItems])

  useEffect(() => {
    if (customCurrentPage) {
      setCurrentPage(customCurrentPage)
    }
  }, [customCurrentPage])

  const handleChangePage = async (page: number) => {
    if (!customCurrentPage) {
      setCurrentPage(page)
    }

    await onChangePage({
      page,
      perPage: ItemsPerPage
    })
  }
  useEffect(() => {
    ;(async () => {
      try {
        setItems(customItems)
        setFilterItems(customItems)
        if (!currentTotalItems) {
          setTotalItems(customItems.length)
        }
      } catch (error) {
        console.error(error)
      }
    })()
  }, [isUpdated, activeLoading, disableLoading, customItems, currentTotalItems])

  const getSearched = async (value: string) => {
    const searchValues = filterItems.filter(item => {
      let searchHeaders = headers.filter(header => header.searchable)
      if (!searchHeaders?.length) {
        searchHeaders = [headers[0]]
      }
      if (searchHeaders?.length) {
        return searchHeaders.some(searchHeader =>
          item[searchHeader.field]?.toLowerCase()?.includes(value)
        )
      }
      return false
    })
    setItems(searchValues)
    setTotalItems(searchValues.length)
  }

  let timeOutId: NodeJS.Timeout
  const onSearchItem = async (value: string) => {
    if (value.length === 0) {
      setItems(filterItems)
      setTotalItems(filterItems.length)
      clearTimeout(timeOutId)
      return
    }

    clearTimeout(timeOutId)
    timeOutId = setTimeout(() => getSearched(value), 500)
  }

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize())
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])
  useEffect(() => {
    if (tableContainerRef.current) {
      const height = tableContainerRef.current.clientHeight
      if (height > windowSize) {
        setHasTopPagination(true)
      } else {
        setHasTopPagination(false)
      }
    }
  }, [items, windowSize])
  const firstItem = useMemo(() => {
    return totalItems === 0
      ? totalItems
      : ItemsPerPage * ((currentPage || 1) - 1) + 1
  }, [ItemsPerPage, currentPage, totalItems])
  const getTotalItems = (initialValue: number): number => {
    let sum = 0
    if (initialValue > 1) {
      return items.length + initialValue - 1
    } else {
      sum = items.length
    }
    return sum
  }
  return (
    <Container className="dataTables_wrapper no-footer">
      {hasTopPagination && (
        <div className="d-flex flex-stack align-items-end mb-5">
          <div className="col-md-5 col-sm-5">
            <div className="dataTables_info" id="kt_datatable">
              Mostrando de {firstItem} até {getTotalItems(firstItem)} de{' '}
              {totalItems} registros
            </div>
          </div>
          <div className="">
            <div className="dataTables_paginate paging_bootstrap_number">
              <Pagination
                total={totalItems}
                itemsPerPage={ItemsPerPage}
                currentPage={currentPage}
                onPageChange={handleChangePage}
                maxPages={maxPages}
                minPages={minPages}
                setMaxPages={setMaxPages}
                setMinPages={setMinPages}
              />
            </div>
          </div>
        </div>
      )}
      {(itemPerPage || search) && (
        <div className="card-header p-0 align-items-center py-5 gap-2 gap-md-5">
          {itemPerPage && (
            <div className="col-md-6 col-sm-6">
              <div className="dataTables_length">
                <label>
                  <select
                    onChange={e => setItemsPerPage(Number(e.target.value))}
                    className="form-control input-sm input-xsmall input-inline"
                    defaultValue="200"
                  >
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="200">200</option>
                  </select>{' '}
                  resultados por página
                </label>
              </div>
            </div>
          )}
          {search && (
            <div className="col-md-6 col-sm-6">
              <div className="dataTables_filter">
                <label>
                  Pesquisar
                  <Search onSearch={value => onSearchItem(value)} />
                </label>
              </div>
            </div>
          )}
        </div>
      )}
      <div className="table-scrollable">
        <table
          className="table table-bordered border table-striped gs-3 table-hover"
          ref={tableContainerRef}
        >
          <TableHeader
            headers={headers}
            onSorting={(field, order, type) => {
              const itemSorted = items.sort((a, b) => {
                const fields = field.split('.')
                let currentFieldA
                let currentFieldB
                if (fields.length === 1) {
                  currentFieldA = a[fields[0]]
                  currentFieldB = b[fields[0]]
                }
                if (fields.length === 2) {
                  currentFieldA = a[fields[0]]?.[fields[1]]
                  currentFieldB = b[fields[0]]?.[fields[1]]
                }
                if (fields.length === 3) {
                  currentFieldA = a[fields[0]]?.[fields[1]]?.[fields[2]]
                  currentFieldB = b[fields[0]]?.[fields[1]]?.[fields[2]]
                }
                if (type === 'date') {
                  return new Date(convertDateToISO(currentFieldA)) >
                    new Date(convertDateToISO(currentFieldB)) && order === 'ASC'
                    ? 1
                    : -1
                }
                if (type === 'monetary') {
                  return Number(currentFieldA.replaceAll(/[',','.']/gi, '')) >
                    Number(currentFieldB.replaceAll(/[',','.']/gi, '')) &&
                    order === 'ASC'
                    ? 1
                    : -1
                }
                return currentFieldA > currentFieldB && order === 'ASC' ? 1 : -1
              })
              setItems([...itemSorted])
            }}
          />
          <tbody>
            {(items.length > 0 &&
              items
                .filter(item => item)
                .map(item => {
                  return (
                    <tr key={item?.id}>
                      {headers.map(header =>
                        header?.field !== 'actions' && !header.custom ? (
                          <td key={`${header?.field}-${item.id}`}>
                            <p
                              style={{
                                textAlign: 'left'
                              }}
                            >
                              {typeof item[header?.field] === 'boolean' &&
                                (item[header?.field] ? 'Sim' : 'Não')}
                              {header?.field.split('.').length === 3 &&
                                item[header?.field.split('.')[0]]?.[
                                  header?.field.split('.')[1]
                                ]?.[header?.field.split('.')[2]]}
                              {header?.field.split('.').length === 2 &&
                                item[header?.field.split('.')[0]]?.[
                                  header?.field.split('.')[1]
                                ]}
                              {header?.field.split('.').length === 1 &&
                                item[header?.field]}
                            </p>
                          </td>
                        ) : header.custom ? (
                          <td>
                            {customHeaders
                              .find(h => h.field === header.field)
                              .element(item)}
                          </td>
                        ) : (
                          <td key={`actions-${item.id}`} className="actions">
                            {actions && (
                              <LinkContainer>
                                {actions.map(action => (
                                  <Link
                                    to={
                                      action.link ||
                                      (action.linkTo && action.linkTo(item)) ||
                                      '#'
                                    }
                                    key={action.name}
                                    title={action.title}
                                    onClick={e => {
                                      if (action.onClick) {
                                        e.preventDefault()
                                        action.onClick(item)
                                      }
                                    }}
                                  >
                                    {action.spanIcon && (
                                      <span className={action.spanIcon} />
                                    )}
                                    {action.icon && (
                                      <FontAwesomeIcon icon={action.icon} />
                                    )}
                                    {action.htmlIcon && action.htmlIcon}
                                  </Link>
                                ))}
                              </LinkContainer>
                            )}
                          </td>
                        )
                      )}
                    </tr>
                  )
                })) || (
              <tr>
                <td style={{ textAlign: 'center' }} colSpan={headers.length}>
                  Nenhum registro encontrado
                </td>
              </tr>
            )}
          </tbody>
          <tfoot />
        </table>
      </div>
      {pagination && (
        <div className="d-flex flex-stack align-items-end mb-5">
          <div className="col-md-5 col-sm-5">
            <div className="dataTables_info" id="kt_datatable">
              Mostrando de {firstItem} até {getTotalItems(firstItem)} de{' '}
              {totalItems} registros
            </div>
          </div>
          <div className="">
            <div className="dataTables_paginate paging_bootstrap_number">
              <Pagination
                total={totalItems}
                itemsPerPage={ItemsPerPage}
                currentPage={currentPage}
                onPageChange={handleChangePage}
                maxPages={maxPages}
                minPages={minPages}
                setMaxPages={setMaxPages}
                setMinPages={setMinPages}
              />
            </div>
          </div>
        </div>
      )}
    </Container>
  )
}

export default CustomDataTable
