export const NOT_ALLOWED_PARTNER = [
  '/commercial/prospects/create',
  '/commercial/queryBatchs',
  '/commercial/queryBatchs/create',
  '/commercial/prospects/reconciliationPartners',
  '/commercial/distributionCampaign',
  '/commercial/distributionCampaign/create',
  '/commercial/reports/partners',
  '/operational/agreementGroups',
  '/operational/agreementGroups/create'
]
export const ALLOWED_ADD_EXPORT_QUERY_BATCH = [
  '/dashboard',
  '/commercial/queryBatchs',
  '/commercial/queryBatchs/create',
  '/commercial/queryBatchs/import/create'
]
