import React, { useCallback, useEffect, useState } from 'react'
import Container from '../../../../components/Container'
import DataTable from '../../../../components/DataTable'
import { nameEntity, nameSource } from '../domain/info'
import { toolsList } from '../domain/tools'
import { breadcrumbList } from '../domain/breadcrumb'
import { headers } from '../domain/headers'
import moment from 'moment'
import { MASKS } from 'common/constants'
import 'react-datepicker/dist/react-datepicker.css'
import { IMaskInput } from 'react-imask'
import { Date as DatePicker } from 'components/Form/date'
import { FilterContainer } from './style'
import { Datalist } from 'components/Datalist'
import api from 'services/api'
import { useHistory } from 'react-router-dom'
import { useToast } from 'hooks/toast'

const List: React.FC = () => {
  const history = useHistory()
  const { addToast } = useToast()
  const [agreementGroupValue, setAgreementGroupValue] = useState({
    name: '',
    value: 0,
    id: 0
  })
  const [startDateValue, setStartDateValue] = useState<Date | null>(null)
  const [endDateValue, setEndDateValue] = useState<Date | null>(null)
  const [parameters, setParameters] = useState<any[]>([])
  const [openDataTable, setOpenDataTable] = useState(true)
  const [agreementGroups, setAgreementGroups] = useState<
    { id: number; value: number; name: string }[]
  >([{ id: 0, value: 0, name: '' }])

  const [partners, setPartners] = useState<
    {
      name: string
      value: number
      id: number
    }[]
  >([
    {
      name: '',
      value: 0,
      id: 0
    }
  ])

  const [partnerValue, setPartnerValue] = useState({
    name: '',
    value: 0,
    id: 0
  })

  const getAgreementGroups = useCallback(async () => {
    const response = await api.get('/operational/agreementGroups/onlyWIthCode')
    const agreementGroupsSelect = response.data?.map(
      (agreementGroup: { id: number; name: string }) => ({
        value: agreementGroup.id,
        name: agreementGroup.name,
        id: agreementGroup.id
      })
    )
    setAgreementGroups(agreementGroupsSelect)
  }, [])
  useEffect(() => {
    getAgreementGroups()
  }, [getAgreementGroups])
  const getPartners = useCallback(async () => {
    try {
      const partnersResponse = await api.get('/commercial/partners')
      const filterPartners = partnersResponse.data?.filter(
        (partner: { id: number; person: { name: string }; active: boolean }) =>
          partner.active
      )
      const partnersSelect = filterPartners?.map(
        (partner: {
          id: number
          store_name: string
          person_cnpj_cpf: string
          active: boolean
        }) =>
          partner.active && {
            value: partner.id,
            name: `${partner.store_name} - ${partner.person_cnpj_cpf}`,
            id: partner.id
          }
      )
      setPartners(partnersSelect)
    } catch (error: any) {
      const statusCode = error?.response.status
      const [, baseUrl, path] = location.pathname.split('/')
      addToast({
        type: 'error',
        title:
          statusCode === 403
            ? 'Você não tem permissão'
            : 'Error ao carregar os parceiros',
        description:
          statusCode === 403
            ? 'Você não tem permissão para acessar essa página'
            : 'Houve um error ao carregar os parceiros, tente novamente mais tarde!'
      })
      if (path.includes('update')) {
        history.push(`/${baseUrl}`)
      } else {
        history.push(`/${baseUrl}/${path}`)
      }
    }
  }, [])

  useEffect(() => {
    getPartners()
  }, [getPartners])

  const handleSetFilter = () => {
    const filters: any = {}

    if (partnerValue?.id) {
      filters.partner_id = partnerValue?.id
    }
    if (agreementGroupValue?.id) {
      filters.agreement_group_id = agreementGroupValue?.id
    }
    if (startDateValue) {
      filters.start_date = startDateValue
    }
    if (endDateValue) {
      filters.end_date = endDateValue
    }
    setParameters([{ ...filters }])
  }

  const dateInputFormat = 'DD/MM/YYYY'

  return (
    <Container
      pageTitle="Listagem"
      breadcrumb={breadcrumbList}
      tools={toolsList}
    >
      <FilterContainer>
        <div className="row">
          <div className="col-md-3">
            <Datalist
              className="col-md-12"
              data={partners}
              label="Parceiro"
              setValue={setPartnerValue}
              value={partnerValue}
            />
          </div>
          <div className="col-md-3">
            <Datalist
              className="col-md-12"
              data={agreementGroups}
              label="Grupo de Convênio"
              setValue={setAgreementGroupValue}
              value={agreementGroupValue}
            />
          </div>
          <DatePicker
            name="start_date"
            className="col-md-3"
            label="Data de início"
            placeholderText="DD/MM/AAAA"
            dateFormat="dd/MM/yyyy"
            selected={startDateValue}
            customInput={
              <IMaskInput
                mask={Date}
                pattern={'d/m/Y'}
                format={date => {
                  return moment(date).format(dateInputFormat)
                }}
                parse={value => {
                  return moment(value, dateInputFormat).toDate()
                }}
              />
            }
            onChange={date => {
              setStartDateValue(date)
            }}
            controlled
          />
          <DatePicker
            name="end_date"
            className="col-md-3"
            label="Data de final"
            placeholderText="DD/MM/AAAA"
            dateFormat="dd/MM/yyyy"
            selected={endDateValue}
            customInput={
              <IMaskInput
                mask={Date}
                pattern={'d/m/Y'}
                format={date => {
                  return moment(date).format(dateInputFormat)
                }}
                parse={value => {
                  return moment(value, dateInputFormat).toDate()
                }}
              />
            }
            onChange={date => {
              setEndDateValue(date)
            }}
            controlled
          />
        </div>
        <footer>
          <button
            className="btn btn-light-primary"
            onClick={() => {
              setPartnerValue({
                name: '',
                value: 0,
                id: 0
              })
              setAgreementGroupValue({
                name: '',
                value: 0,
                id: 0
              })
              setStartDateValue(null)
              setEndDateValue(null)
              setParameters([])
              setOpenDataTable(false)
            }}
          >
            LIMPAR
          </button>
          <button
            className="btn btn-primary"
            onClick={() => {
              handleSetFilter()
              setOpenDataTable(true)
            }}
          >
            BUSCAR
          </button>
        </footer>
        <div className="separator" />
      </FilterContainer>
      <DataTable
        entity={nameEntity}
        source={nameSource}
        format={{ orderBy: 'id' }}
        notHasChildren
        headers={headers}
        customHeaders={[
          {
            name: 'Criado em',
            field: 'created_at',
            sortable: false,
            element: item => {
              return (
                <p>
                  {moment(item.created_at, MASKS.DATE.ISO_WITH_LOCALE)
                    .subtract(3, 'hours')
                    .format(MASKS.DATE.LOCALE_WITH_TIME)}
                </p>
              )
            }
          }
        ]}
        searchParameters={parameters}
      />
    </Container>
  )
}

export default List
