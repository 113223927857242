import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import api from '../../../../../services/api'
import Form, { Input, Select } from '../../../../../components/Form'
import { useHistory } from 'react-router-dom'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { useUpdateDataTable } from '../../../../../hooks/dataTable'
import { apiCreate, apiUpdate } from '../../domain/api'
import { nameActions } from '../../domain/info'
import { Datalist } from 'components/Datalist'
import { Loading } from 'components/Loading'
import { useAuth } from 'hooks/auth'
import generateOptions from 'utlis/generateOptions'
import Papa from 'papaparse'
import {
  HEADERS_PROSPECT_FILE,
  HEADERS_QUERY_BATCH_FILE
} from 'common/constants/Commercial/Prospects'
import modelImportQueryBatch from '../../../../../assets/model-import-query-batch.csv'

type IsOpenInModalProps = {
  idParent: number
  handleOnClose: () => void
}

type QueryBatchData = {
  agreement_group_id: string
  api_name: string
  quantity_requested: number
  status: string
}

type TypesFormProps = {
  isOpenInModal?: false | IsOpenInModalProps
  initialValues?: QueryBatchData & {
    idUpdate: number
  }
  typeForm: 'create' | 'update'
}
export const FormQueryBatchImport = ({
  isOpenInModal,
  initialValues,
  typeForm
}: TypesFormProps): JSX.Element => {
  const { user } = useAuth()
  const { addToast } = useToast()
  const history = useHistory()
  const { updateDataTable } = useUpdateDataTable()
  const [agreementGroups, setAgreementGroups] = useState<
    {
      name: string
      value: number
      id: number
    }[]
  >([
    {
      name: '',
      value: 0,
      id: 0
    }
  ])
  const [agreementGroupValue, setAgreementGroupValue] = useState<{
    id: number
    value: number
    name: string
    allowed_queries?: string[]
  }>({
    name: '',
    value: 0,
    id: 0
  })
  const [defaultValues, setDefaultValues] = useState<QueryBatchData>()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const getAgreementGroups = useCallback(async () => {
    setIsLoading(true)
    const response = await api.get('/operational/agreementGroups/onlyWIthCode')
    const agreementGroupsSelect = response.data?.map(
      (agreementGroup: {
        id: number
        name: string
        allowed_queries?: string[]
      }) => ({
        value: agreementGroup.id,
        name: agreementGroup.name,
        allowed_queries: agreementGroup.allowed_queries || [],
        id: agreementGroup.id
      })
    )
    setAgreementGroups(agreementGroupsSelect)
    setIsLoading(false)
  }, [])

  useEffect(() => {
    getAgreementGroups()
  }, [getAgreementGroups])

  useEffect(() => {
    if (initialValues) {
      setDefaultValues({
        ...initialValues
      })
    }
  }, [initialValues])

  const { activeLoading, disableLoading } = useLoading()

  const apiNameOptions = useMemo(() => {
    const options = generateOptions({
      user,
      options: [
        {
          name: 'Credcesta',
          value: 'credcesta'
        },
        {
          name: 'Titulo de capitalização Cap Já',
          value: 'credcesta_card'
        },
        {
          name: 'Santander correntista',
          value: 'santander_correntista'
        },
        {
          name: 'Santander margem',
          value: 'santander_margem'
        },
        {
          name: 'Lemit',
          value: 'lemit'
        },
        {
          name: 'QI',
          value: 'qi'
        }
      ]
    })
    return options
      .filter(item => {
        if (!agreementGroupValue?.allowed_queries?.length) {
          return true
        }
        return agreementGroupValue?.allowed_queries?.includes(
          `allowed_${item.value}`
        )
      })
      .sort((currentItem, nextItem) =>
        currentItem.name.localeCompare(nextItem.name)
      )
  }, [agreementGroupValue?.allowed_queries, user])

  const onSubmitForm = async (data: any) => {
    data.agreement_group_id = agreementGroupValue?.id?.toString()
    try {
      const formData = new FormData()
      formData.append('file', data.file[0])
      formData.append('agreement_group_id', data.agreement_group_id)
      formData.append('api_name', data.api_name)
      try {
        activeLoading()
        await api.post('/commercial/queryBatchs/import', formData)
        disableLoading()
        updateDataTable()
        addToast({
          type: 'success',
          title: 'Registro criado',
          description: 'Registro criado com sucesso'
        })
        history.push(nameActions.read.to)
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Erro ao adicionar o registro',
          description:
            'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
        })
        disableLoading()
        updateDataTable()
      }
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro no cadastro',
        description:
          'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
      })
    }
  }

  const handleOnChangeFile = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      Papa.parse(event.target.files[0], {
        header: true,
        skipEmptyLines: true,
        complete: function (results: any) {
          const headers = Object.keys(results?.data?.[0])
          HEADERS_QUERY_BATCH_FILE.every((header, index) => {
            if (headers[index] !== header) {
              addToast({
                type: 'error',
                title: 'Arquivo Invalido',
                description: `Esse aquivo é invalido, faça download do arquivo modelo, ajuste os campos: ${header} e tente novamente`
              })
              event.target.value = null
              return false
            }
            return header
          })
        }
      })
      return event.target.value
    },
    [addToast]
  )

  return (
    <>
      <Loading isActive={isLoading} />
      <Form onSubmit={onSubmitForm} defaultValues={defaultValues}>
        <div className="form">
          <div className="row mb-5">
            <Datalist
              className="col-6"
              data={agreementGroups}
              label="Grupo de Convênio"
              setValue={setAgreementGroupValue}
              value={agreementGroupValue}
            />
            <Select
              name="api_name"
              label="Tipo"
              className="col-3"
              options={apiNameOptions}
              blank
              rules={{ required: true }}
              disabled={!agreementGroupValue.id}
            />
            <Input
              name="file"
              label="Arquivo"
              type="file"
              className="col"
              accept=".csv"
              rules={{ required: true }}
              onChange={handleOnChangeFile}
              disabled={!agreementGroupValue.id}
              CustomLabelContainer={({ children }) => (
                <label className="col-form-label fw-bold fs-6 d-flex">
                  {children}
                  <a
                    href={modelImportQueryBatch}
                    download="modelo-de-importação"
                    className="ms-5 d-flex align-items-center"
                  >
                    <span className="fa fa-download me-2"></span>
                    <p>Modelo</p>
                  </a>
                </label>
              )}
            />
          </div>
          <div className="card-footer d-flex justify-content-end py-6 px-9">
            <button type="submit" className="btn btn-primary">
              Salvar
            </button>
          </div>
        </div>
      </Form>
    </>
  )
}
