import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import api from '../../../../../services/api'
import Form, { Input, Select } from '../../../../../components/Form'
import { useHistory } from 'react-router-dom'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { nameActions } from '../../domain/info'
import { dateInputFormat } from 'common/constants'
import { Datalist } from 'components/Datalist'
import { Loading } from 'components/Loading'
import { Date as DatePicker } from 'components/Form/date'
import { IMaskInput } from 'react-imask'
import moment from 'moment'
import { CLIENTS_RISK_CREDIT_OPTIONS } from 'pages/Commercial/Prospects/List'
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form'
import { BRL, genericMaskWithTwoZeroWithPoint } from 'utlis/mask'

type DistributionCampaignData = {
  type_margin: string
  agency?: string
  start_margin_value?: number
  end_margin_value?: number
  start_birth_date?: string
  end_birth_date?: string
  risk_credit?: string
  agreement_group_id?: number
}

export enum ApisOptions {
  SIM = 1,
  LEMIT = 2,
  CREDCESTA = 3,
  FGTS = 4
}

// Função para embaralhar os prospects usando o algoritmo Fisher-Yates (Knuth shuffle)
const shuffleProspects = (array: any[]) => {
  // Cria uma cópia do array para não modificar o original diretamente
  const shuffled = [...array]

  // Embaralha o array
  for (let i = shuffled.length - 1; i > 0; i--) {
    // Escolhe um índice aleatório de 0 até i
    const j = Math.floor(Math.random() * (i + 1))
    // Troca os elementos nas posições i e j
    ;[shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]]
  }

  return shuffled
}

// Função para gerar o nome da campanha com base no convênio selecionado
const generateCampaignName = (agreementName = '') => {
  const baseText = `Campanha de distribuição ${moment().format('DD/MM/YYYY')}`
  return agreementName ? `${baseText} - ${agreementName}` : baseText
}

export const FormDistributionCampaign = (): JSX.Element => {
  const { addToast } = useToast()
  const history = useHistory()

  const [currentCampaignName, setCurrentCampaignName] = useState(
    generateCampaignName()
  )

  const registerDistributionCampaign = useForm({
    mode: 'onChange', // Ativa validação em tempo real
    defaultValues: {
      partners: [],
      name: currentCampaignName
    }
  })
  const { fields, append, remove } = useFieldArray({
    control: registerDistributionCampaign.control,
    name: 'partners'
  })
  const [startBirthDateValue, setStartBirthDateValue] = useState<Date>()
  const [endBirthDateValue, setEndBirthDateValue] = useState<Date>()
  const [isLoading, setIsLoading] = useState(false)
  const [prospects, setProspects] = useState([])
  const [filtersData, setFiltersData] = useState(null)
  const [totalRequestedQuantity, setTotalRequestedQuantity] =
    useState<number>(0)
  const [quantityError, setQuantityError] = useState<string>('')
  const [startMarginValueValue, setStartMarginValueValue] = useState<string>('')
  const [endMarginValueValue, setEndMarginValueValue] = useState<string>('')
  // Add a state to track errors for each partner
  const [partnerErrors, setPartnerErrors] = useState<{ [key: number]: string }>(
    {}
  )
  const [agreementGroups, setAgreementGroups] = useState<
    {
      name: string
      value: number
      id: number
    }[]
  >([
    {
      name: '',
      value: 0,
      id: 0
    }
  ])
  const [partners, setPartners] = useState<
    {
      name: string
      value: number
      id: number
    }[]
  >([
    {
      name: '',
      value: 0,
      id: 0
    }
  ])
  const [agreementGroupValue, setAgreementGroupValue] = useState({
    name: '',
    value: 0,
    id: 0
  })

  // Use um ref para manter o registro das quantidades entre re-renderizações
  const quantitiesRef = useRef<{ [key: string]: string }>({})

  const { activeLoading, disableLoading } = useLoading()

  // Observar os valores dos campos de quantidade de todos os parceiros
  const partnersValues = useWatch({
    control: registerDistributionCampaign.control,
    name: 'partners'
  })

  // Atualiza o nome da campanha quando o convênio é alterado
  useEffect(() => {
    const newCampaignName = generateCampaignName(agreementGroupValue?.name)
    setCurrentCampaignName(newCampaignName)
    registerDistributionCampaign.setValue('name', newCampaignName)
  }, [agreementGroupValue, registerDistributionCampaign])

  // Função para atualizar o total de prospects solicitados
  const updateTotalRequestedQuantity = useCallback(() => {
    // Obtém valores atuais do form
    const formValues = registerDistributionCampaign.getValues()

    // Se não tiver parceiros, zera o total
    if (!formValues.partners || formValues.partners.length === 0) {
      setTotalRequestedQuantity(0)
      return
    }

    // Calcula o total somando todas as quantidades
    const total = formValues.partners.reduce((sum, partner, index) => {
      // Obtém a quantidade do campo ou do ref se estiver disponível
      const partnerKey = `partner-${index}`
      const quantityValue =
        partner?.quantity || quantitiesRef.current[partnerKey] || '0'
      const quantity = parseInt(quantityValue, 10)
      return sum + (isNaN(quantity) ? 0 : quantity)
    }, 0)

    setTotalRequestedQuantity(total)

    // Atualiza a mensagem de erro, se necessário
    if (total > prospects.length) {
      setQuantityError(
        `A quantidade solicitada (${total}) excede o número de prospects disponíveis (${prospects.length})`
      )
    } else {
      setQuantityError('')
    }
  }, [registerDistributionCampaign, prospects.length])

  // Atualizar o total quando os valores dos campos mudam
  useEffect(() => {
    updateTotalRequestedQuantity()
  }, [partnersValues, updateTotalRequestedQuantity])

  // Função para lidar com a mudança de quantidade
  const handleQuantityChange = useCallback(
    (index: number, value: string) => {
      // Armazena o valor no ref para manter entre re-renderizações
      const partnerKey = `partner-${index}`
      quantitiesRef.current[partnerKey] = value

      // Atualiza o valor no formulário
      registerDistributionCampaign.setValue(`partners.${index}.quantity`, value)

      // Validate the quantity against available prospects
      const quantity = parseInt(value, 10)
      if (!isNaN(quantity) && quantity > prospects.length) {
        setPartnerErrors(prev => ({
          ...prev,
          [index]: `A quantidade solicitada (${quantity}) excede o número de prospects disponíveis (${prospects.length})`
        }))
      } else {
        // Clear error if valid
        setPartnerErrors(prev => {
          const newErrors = { ...prev }
          delete newErrors[index]
          return newErrors
        })
      }

      // Calcula o novo total
      updateTotalRequestedQuantity()
    },
    [
      registerDistributionCampaign,
      updateTotalRequestedQuantity,
      prospects.length
    ]
  )

  const getAgreementGroups = useCallback(async () => {
    activeLoading()
    const response = await api.get('/operational/agreementGroups/onlyWIthCode')
    const agreementGroupsSelect = response.data?.map(
      (agreementGroup: { id: number; name: string }) => ({
        value: agreementGroup.id,
        name: agreementGroup.name,
        id: agreementGroup.id
      })
    )
    setAgreementGroups(agreementGroupsSelect)
    disableLoading()
  }, [activeLoading, disableLoading])

  const getPartners = useCallback(async () => {
    activeLoading()
    try {
      const partnersResponse = await api.get('/commercial/partners')
      const filterPartners = partnersResponse.data?.filter(
        (partner: { id: number; person: { name: string }; active: boolean }) =>
          partner.active
      )
      const partnersSelect = filterPartners?.map(
        (partner: {
          id: number
          store_name: string
          person_cnpj_cpf: string
          active: boolean
        }) =>
          partner.active && {
            value: partner.id,
            name: `${partner.store_name} - ${partner.person_cnpj_cpf}`,
            id: partner.id
          }
      )
      setPartners(partnersSelect)
      disableLoading()
    } catch (error: any) {
      const statusCode = error?.response.status
      const [, baseUrl, path] = location.pathname.split('/')
      disableLoading()
      addToast({
        type: 'error',
        title:
          statusCode === 403
            ? 'Você não tem permissão'
            : 'Error ao carregar os parceiros',
        description:
          statusCode === 403
            ? 'Você não tem permissão para acessar essa página'
            : 'Houve um error ao carregar os parceiros, tente novamente mais tarde!'
      })
      if (path.includes('update')) {
        history.push(`/${baseUrl}`)
      } else {
        history.push(`/${baseUrl}/${path}`)
      }
    }
  }, [activeLoading, addToast, disableLoading, history])

  useEffect(() => {
    getAgreementGroups()
  }, [getAgreementGroups])

  useEffect(() => {
    getPartners()
  }, [getPartners])

  const onSubmitForm = useCallback(
    async (data: any) => {
      // Verificação final antes de submeter
      // if (totalRequestedQuantity > prospects.length) {
      //   addToast({
      //     type: 'error',
      //     title: 'Erro na distribuição',
      //     description: `A quantidade solicitada (${totalRequestedQuantity}) excede o número de prospects disponíveis (${prospects.length})`
      //   })
      //   return
      // }

      if (!data.name?.length) {
        data.name = currentCampaignName
      }

      setIsLoading(true)

      try {
        for (const partner of data?.partners) {
          const quantity = Number(partner.quantity)
          if (quantity > 0 && partner.id) {
            // Obtém o slice de prospects para este parceiro
            const partnerProspects = prospects.slice(0, quantity)

            // Cria a tarefa de distribuição
            await api.post('/commercial/distributionCampaigns', {
              name: data.name,
              partner_id: partner.id,
              agreement_group_id: agreementGroupValue?.id,
              quantity_requested: quantity,
              filters: filtersData,
              prospect_ids: partnerProspects.map(
                (item: { id: number }) => item.id
              )
            })
          }
        }

        addToast({
          type: 'success',
          title: 'Distribuição realizada',
          description: 'Os prospects foram distribuídos com sucesso!'
        })

        // Limpa o formulário
        registerDistributionCampaign.reset()
        quantitiesRef.current = {} // Limpa as referências de quantidade
        setTotalRequestedQuantity(0)
        setPartnerErrors({}) // Clear partner errors
        history.push(nameActions.read.to)
      } catch (error: any) {
        addToast({
          type: 'error',
          title: 'Erro na distribuição',
          description:
            error.message || 'Ocorreu um erro ao distribuir os prospects'
        })
      } finally {
        setIsLoading(false)
      }
    },
    [
      prospects,
      addToast,
      registerDistributionCampaign,
      history,
      agreementGroupValue?.id,
      filtersData,
      currentCampaignName
    ]
  )

  const handleSetFilter = useCallback(
    async (data: DistributionCampaignData) => {
      if (!agreementGroupValue?.id) {
        addToast({
          type: 'info',
          title: 'Selecione ao meno um convênio',
          description: 'Você precisa escolher ao menos um convênio'
        })
        return
      }
      setIsLoading(true)
      let typeMargin = 1
      if (data.type_margin) {
        if (!Number(data.type_margin)) {
          typeMargin = -1
        }
      }

      if (startMarginValueValue?.length) {
        data.start_margin_value = BRL(startMarginValueValue).value * typeMargin
      }
      if (endMarginValueValue?.length) {
        data.end_margin_value = BRL(endMarginValueValue).value * typeMargin
      }
      const formData = {
        agency: data.agency || undefined,
        start_margin_value: data.start_margin_value || undefined,
        end_margin_value: data.end_margin_value || undefined,
        start_birth_date: data.start_birth_date || undefined,
        end_birth_date: data.end_birth_date || undefined,
        risk_credit: data.risk_credit || undefined,
        agreement_group_id: agreementGroupValue?.id || undefined
      }

      setFiltersData(formData)

      try {
        const responseProspects = await api.post(
          '/commercial/distributionCampaigns/listProspects',
          formData
        )

        // Embaralha os prospects antes de defini-los no estado
        const shuffledProspects = shuffleProspects(
          responseProspects.data?.prospects || []
        )
        setProspects(shuffledProspects)

        // Reset distribution value and form values
        setTotalRequestedQuantity(0)
        setQuantityError('')
        // Reset partner errors when new prospects are loaded
        setPartnerErrors({})

        // Mantém os parceiros, mas limpa os valores de quantidade
        const currentPartners =
          registerDistributionCampaign.getValues().partners
        if (currentPartners && currentPartners.length > 0) {
          currentPartners.forEach((partner, index) => {
            registerDistributionCampaign.setValue(
              `partners.${index}.quantity`,
              ''
            )
            const partnerKey = `partner-${index}`
            quantitiesRef.current[partnerKey] = ''
          })
        }
      } catch (error: any) {
        addToast({
          type: 'error',
          title: 'Erro ao adicionar o registro',
          description:
            error?.response?.data?.message ||
            'Ocorreu um erro ao distribuir os prospects'
        })
      } finally {
        setIsLoading(false)
      }
    },
    [
      addToast,
      agreementGroupValue?.id,
      endMarginValueValue,
      registerDistributionCampaign,
      startMarginValueValue
    ]
  )

  const handleAddPartner = () => {
    append({ id: '', name: '', quantity: '' })
  }

  // Função para lidar com a seleção de parceiro
  const handlePartnerSelect = useCallback(
    (index: number, value: any) => {
      // Preserva o valor de quantidade existente (se houver)
      const partnerKey = `partner-${index}`
      const currentQuantity = quantitiesRef.current[partnerKey] || ''

      // Atualiza o parceiro no formulário
      registerDistributionCampaign.setValue(`partners.${index}`, {
        ...value,
        quantity: currentQuantity
      })

      // Mantém a consistência do cálculo total
      updateTotalRequestedQuantity()
    },
    [registerDistributionCampaign, updateTotalRequestedQuantity]
  )

  // Botão de distribuir desabilitado se houver erro ou parceiros sem ID
  const isDistributeButtonDisabled = useMemo(() => {
    // Verifica se tem pelo menos um parceiro
    const formValues = registerDistributionCampaign.getValues()
    // Verifica se há pelo menos um parceiro
    const hasPartners = formValues.partners?.length > 0

    // Verifica se TODOS os parceiros adicionados têm ID
    const allPartnersHaveId = formValues.partners?.every(p => p.id)

    // Verifica se há pelo menos um parceiro com quantidade válida
    const hasValidPartner = formValues.partners?.some(
      p => p.id && Number(p.quantity) > 0
    )

    // Check if any partner has quantity errors
    const hasQuantityErrors = Object.keys(partnerErrors).length > 0

    return (
      !hasPartners ||
      !allPartnersHaveId || // Nova verificação - todos os parceiros precisam ter ID
      !hasValidPartner ||
      hasQuantityErrors
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    registerDistributionCampaign,
    totalRequestedQuantity,
    prospects.length,
    quantityError,
    partnersValues,
    partnerErrors
  ])

  // Função para limpar filtros
  const handleClearFilters = () => {
    setProspects([])
    setTotalRequestedQuantity(0)
    setQuantityError('')
    setStartMarginValueValue('')
    setEndMarginValueValue('')
    setStartBirthDateValue(null)
    setEndBirthDateValue(null)
    setPartnerErrors({}) // Clear partner errors
    setAgreementGroupValue({
      name: '',
      value: 0,
      id: 0
    })
    // Atualiza o nome da campanha para o padrão sem convênio
    setCurrentCampaignName(generateCampaignName())
    registerDistributionCampaign.setValue('name', generateCampaignName())
  }

  return (
    <>
      <Loading isActive={isLoading} />
      <Form
        onSubmit={handleSetFilter}
        defaultValues={{
          type_margin: '1',
          risk_credit: ''
        }}
      >
        <div className="row">
          <Datalist
            data={agreementGroups}
            label="Grupo de Convênio"
            setValue={setAgreementGroupValue}
            value={agreementGroupValue}
          />
          <div className="col-md-3">
            <Input name="agency" label="Agencia" id="agency" />
          </div>
          <div className="col-md-3">
            <Select
              name="type_margin"
              label="Tipo de Margem"
              id="type_margin"
              options={[
                {
                  name: 'Positiva',
                  value: '1'
                },
                {
                  name: 'Negativa',
                  value: '0'
                }
              ]}
              blank
            />
          </div>
          <div className="col-md-3">
            <Input
              name="start_margin_value"
              label="Margem inicial"
              id="start_margin_value"
              onChange={({ target }) =>
                setStartMarginValueValue(
                  genericMaskWithTwoZeroWithPoint(target.value)
                )
              }
              value={startMarginValueValue}
            />
          </div>
          <div className="col-md-3">
            <Input
              name="end_margin_value"
              label="Margem final"
              id="end_margin_value"
              onChange={({ target }) =>
                setEndMarginValueValue(
                  genericMaskWithTwoZeroWithPoint(target.value)
                )
              }
              value={endMarginValueValue}
            />
          </div>
          <DatePicker
            name="start_birth_date"
            className="col-md-3"
            label="Data de nascimento inicial"
            placeholderText="DD/MM/AAAA"
            dateFormat="dd/MM/yyyy"
            selected={startBirthDateValue}
            customInput={
              <IMaskInput
                mask={Date}
                pattern={'d/m/Y'}
                format={date => {
                  return moment(date).format(dateInputFormat)
                }}
                parse={value => {
                  return moment(value, dateInputFormat).toDate()
                }}
              />
            }
            onChange={date => {
              setStartBirthDateValue(date)
            }}
            controlled
          />
          <DatePicker
            name="end_birth_date"
            className="col-md-3"
            label="Data de nascimento final"
            placeholderText="DD/MM/AAAA"
            dateFormat="dd/MM/yyyy"
            selected={endBirthDateValue}
            customInput={
              <IMaskInput
                mask={Date}
                pattern={'d/m/Y'}
                format={date => {
                  return moment(date).format(dateInputFormat)
                }}
                parse={value => {
                  return moment(value, dateInputFormat).toDate()
                }}
              />
            }
            onChange={date => {
              setEndBirthDateValue(date)
            }}
            controlled
          />
          <div className="col-md-3">
            <Select
              name="risk_credit"
              label="Risco de crédito"
              id="risk_credit"
              options={CLIENTS_RISK_CREDIT_OPTIONS}
              blank
            />
          </div>
        </div>
        <footer className="mt-2 card-footer d-flex justify-content-end py-6 ps-9 pe-0">
          <button
            className="btn btn-light-primary me-5"
            onClick={handleClearFilters}
            type="button"
          >
            LIMPAR
          </button>
          <button className="btn btn-primary" type="submit">
            BUSCAR
          </button>
        </footer>
      </Form>

      {prospects?.length > 0 && (
        <>
          <div className="alert alert-primary mt-4 mb-4">
            <div className="d-flex align-items-center">
              <i className="fas fa-info-circle me-2"></i>
              <span>Prospects disponíveis: {prospects.length}</span>
            </div>
          </div>
        </>
      )}

      {prospects?.length > 0 && (
        <form
          onSubmit={registerDistributionCampaign.handleSubmit(onSubmitForm)}
        >
          <div className="row mb-4">
            <div className="col-md-6">
              <Controller
                control={registerDistributionCampaign.control}
                name="name"
                rules={{ required: false }}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <label className="form-label">Nome da Campanha</label>
                    <input
                      {...field}
                      type="text"
                      className={`form-control form-control-solid ${
                        error ? 'is-invalid' : ''
                      }`}
                    />
                    {error && (
                      <div className="invalid-feedback">{error.message}</div>
                    )}
                  </>
                )}
              />
            </div>
          </div>
          <div className="mt-4">
            <div className="card-toolbar mb-3">
              <button
                type="button"
                className="btn btn-sm btn-light-primary"
                onClick={handleAddPartner}
              >
                <i className="fas fa-plus me-2"></i>
                Adicionar Parceiro
              </button>
            </div>

            {/* {totalRequestedQuantity > 0 && (
            <div className="d-flex justify-content-end mt-2 mb-3">
              <div
                className={`badge ${
                  quantityError ? 'badge-danger' : 'badge-success'
                } fs-6 py-2 px-3`}
              >
                Total solicitado: {totalRequestedQuantity} / {prospects.length}
              </div>
            </div>
          )} */}

            {/* {quantityError && (
            <div className="alert alert-danger d-flex align-items-center">
              <i className="fas fa-exclamation-triangle me-2"></i>
              <span>{quantityError}</span>
            </div>
          )} */}

            <div className="py-3">
              {fields.length === 0 ? (
                <div className="alert alert-warning d-flex align-items-center">
                  <i className="fas fa-exclamation-triangle me-2"></i>
                  <span>Adicione pelo menos um parceiro para distribuição</span>
                </div>
              ) : (
                fields.map((field, index) => (
                  <div key={field.id} className="row mb-4">
                    <div className="row d-flex align-items-end border-bottom pb-4">
                      <div className="col-md-6">
                        <Controller
                          control={registerDistributionCampaign.control}
                          name={`partners.${index}`}
                          render={({ field: { value } }) => (
                            <Datalist
                              className="col-md-12"
                              data={partners}
                              label="Parceiro"
                              setValue={(item: any) =>
                                handlePartnerSelect(index, item)
                              }
                              value={value as any}
                            />
                          )}
                        />
                      </div>
                      <div className="col-md-3">
                        <label className="form-label">Quantidade</label>
                        <input
                          className={`form-control form-control-solid ${
                            partnerErrors[index] ? 'is-invalid' : ''
                          }`}
                          value={
                            registerDistributionCampaign.watch(
                              `partners.${index}.quantity`
                            ) || ''
                          }
                          onChange={e =>
                            handleQuantityChange(index, e.target.value)
                          }
                        />
                      </div>
                      <div className="col-md-2 d-flex align-items-center">
                        <button
                          type="button"
                          className="btn btn-icon btn-light-danger"
                          onClick={() => {
                            // Remove a referência ao remover o parceiro
                            const partnerKey = `partner-${index}`
                            delete quantitiesRef.current[partnerKey]
                            // Also remove any errors for this partner
                            setPartnerErrors(prev => {
                              const newErrors = { ...prev }
                              delete newErrors[index]
                              return newErrors
                            })
                            remove(index)
                            // Recalcula após remover
                            setTimeout(updateTotalRequestedQuantity, 0)
                          }}
                        >
                          <i className="fas fa-trash"></i>
                        </button>
                      </div>
                    </div>

                    {/* Error message block that appears below the partner row */}
                    {partnerErrors[index] && (
                      <div className="col-12 mt-2">
                        <div className="alert alert-danger py-2 px-4">
                          <i className="fas fa-exclamation-triangle me-2"></i>
                          {partnerErrors[index]}
                        </div>
                      </div>
                    )}
                  </div>
                ))
              )}
            </div>
            <div className="d-flex justify-content-end py-4">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={isDistributeButtonDisabled}
              >
                Distribuir
              </button>
            </div>
          </div>
        </form>
      )}
    </>
  )
}
