import React from 'react'
import PrivateRoutes from '../Route'
import List from '../../pages/Commercial/Prospects/List'
import Create from '../../pages/Commercial/Prospects/Create'
import View from '../../pages/Commercial/Prospects/View'
import { CustomSwitch } from '../../components/CustomSwitch'
import Update from '../../pages/Commercial/Prospects/Update'
import Reconciliation from '../../pages/Commercial/ReconciliationPartners/Create'

export const ProspectsRouter = (): JSX.Element => (
  <CustomSwitch>
    <PrivateRoutes path="/commercial/prospects" exact component={List} />
    <PrivateRoutes
      path="/commercial/prospects/create"
      exact
      component={Create}
    />
    <PrivateRoutes
      path="/commercial/prospects/view/:id"
      exact
      component={View}
    />
    <PrivateRoutes
      path="/commercial/prospects/update/:id"
      exact
      component={Update}
    />
    <PrivateRoutes
      path="/commercial/prospects/reconciliationPartners"
      exact
      component={Reconciliation}
    />
  </CustomSwitch>
)
